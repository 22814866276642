<template>
  <div class="client-whiteboard" :class="{ 'external-erasing': externalErasing }" id="liveWhiteboard">
    <canvas class="client-whiteboard__canvas" ref="liveCanvas"></canvas>
    <div class="whiteboard-cursor" :class="[{ [`whiteboard-cursor--${color}`]: color && mode === 'pen' }]"
      :style="`width: ${cursorWidth}px; height: ${cursorWidth}px;`" ref="cursor"></div>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
import { v4 as uuid } from "uuid";
import whiteboardApi from "@/api/whiteboard";

export default {
  name: "LiveBoard",
  props: ["mode", "color", "strokeWidth", "eraserRadius"],
  data() {
    return {
      canvas: null,
      context: null,
      drawing: false,
      erasing: false,
      externalErasing: false,
      current: {},
      currentPath: {},
      pathHistory: [],
    };
  },
  computed: {
    tutorSession() {
      return this.$store.state.session.tutorSession;
    },
    student() {
      return this.$store.state.session.studentTutoring;
    },
    cursorWidth() {
      if (this.mode === "pen") {
        return this.strokeWidth;
      } else if (this.mode === "eraser") {
        return this.eraserRadius * 2;
      }

      return 5;
    },
  },
  mounted() {
    this.canvas = this.$refs.liveCanvas;
    this.context = this.canvas.getContext("2d");

    this.canvas.addEventListener("mousedown", this.onMouseDown, false);
    this.canvas.addEventListener("mouseup", this.onMouseUp, false);
    this.canvas.addEventListener("mouseout", this.onMouseUp, false);
    this.canvas.addEventListener(
      "mousemove",
      this.throttle(this.onMouseMove, 25),
      false
    );

    // touch events
    this.canvas.addEventListener("touchstart", this.onMouseDown, false);
    this.canvas.addEventListener("touchend", this.onMouseUp, false);
    this.canvas.addEventListener("touchcancel", this.onMouseUp, false);
    this.canvas.addEventListener(
      "touchmove",
      this.throttle(this.onMouseMove, 25),
      false
    );

    // window events
    this.onResize();
    window.addEventListener("mousedown", this.defaultMouseDown, false);
    window.addEventListener("mousemove", this.followCursor, false);

    // eventbus events
    eventBus.$on("external-erasing", this.onExternalEraseEvent);
    eventBus.$on("stop-external-erasing", this.onStopExternalErasingEvent);
    eventBus.$on("clear-whiteboard", this.onClear);
    eventBus.$on("resize-canvas", this.onResize);
    eventBus.$on("new-path", this.onNewPath);
  },
  beforeUnmount() {
    //  window events
    window.removeEventListener("mousedown", this.defaultMouseDown, false);
    window.removeEventListener("mousemove", this.followCursor, false);

    // eventbus events
    eventBus.$off("external-erasing", this.onExternalEraseEvent);
    eventBus.$off("stop-external-erasing", this.onStopExternalErasingEvent);
    eventBus.$off("clear-whiteboard", this.onClear);
    eventBus.$off("resize-canvas", this.onResize);
    eventBus.$off("new-path", this.onNewPath);
  },
  methods: {
    defaultMouseDown(e) {
      e.preventDefault();
    },
    onClear() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.currentPath = {};
      this.pathHistory = [];
      this.drawing = false;
      this.erasing = false;
    },
    throttle(callback, delay) {
      let previousCall = new Date().getTime();
      return function () {
        const time = new Date().getTime();

        if (time - previousCall >= delay) {
          previousCall = time;
          /* eslint-disable */
          callback.apply(null, arguments);
          /*eslint-enable*/
        }
      };
    },
    onResize() {
      this.canvas.width = document.getElementById("liveWhiteboard").clientWidth;
      this.canvas.height =
        document.getElementById("liveWhiteboard").clientHeight;
      this.drawPaths(this.pathHistory);
    },
    getTouchOffset(e) {
      const rect = e.target.getBoundingClientRect();
      if (e.targetTouches) {
        const x = e.targetTouches[0].pageX - rect.left;
        const y = e.targetTouches[0].pageY - rect.top;
        return { x, y };
      } else {
        return null;
      }
    },
    onMouseDown(e) {
      this.handleMouseDown(e);
    },
    handleMouseDown(e) {
      if (this.mode === "pen") {
        this.currentPath = { id: uuid(), eraser: false, points: [] };
        this.drawing = true;
        this.context.beginPath();
        this.context.globalCompositeOperation = "source-over";
        this.context.strokeStyle = this.color;
        this.context.lineWidth = this.strokeWidth;
        this.context.lineJoin = "round";
        this.context.lineCap = "round";
        this.current.x = e.offsetX || this.getTouchOffset(e)?.x;
        this.current.y = e.offsetY || this.getTouchOffset(e)?.y;
        this.context.moveTo(this.current.x, this.current.y);

        const w = this.canvas.width;
        const h = this.canvas.height;
        const pointData = {
          x: this.current?.x / w,
          y: this.current?.y / h,
          color: this.color,
          strokeWidth: this.strokeWidth,
          pathId: this.currentPath.id,
        };

        this.currentPath.points.push(pointData);
      } else if (this.mode === "eraser") {
        this.erasing = true;
        this.currentPath = { id: uuid(), eraser: true, points: [] };
        this.current.x = e.offsetX || this.getTouchOffset(e)?.x;
        this.current.y = e.offsetY || this.getTouchOffset(e)?.y;

        const w = this.canvas.width;
        const h = this.canvas.height;
        const pointData = {
          pathId: this.currentPath.id,
          x: this.current?.x / w,
          y: this.current?.y / h,
          radius: this.eraserRadius,
          erase: true,
        };

        this.currentPath.points.push(pointData);
      }
    },
    onMouseUp(e) {
      this.handleMouseUp(e);
    },
    handleMouseUp() {
      if (!this.drawing && !this.erasing) {
        return;
      }

      if (this.drawing) {
        this.drawing = false;
        this.pathHistory.push(this.currentPath);
      } else if (this.erasing) {
        this.erasing = false;
      }

      whiteboardApi.newPath(this.currentPath);
    },
    onMouseMove(e) {
      if (!this.drawing && !this.erasing) return;

      const w = this.canvas.width;
      const h = this.canvas.height;
      const x = e.offsetX || this.getTouchOffset(e)?.x;
      const y = e.offsetY || this.getTouchOffset(e)?.y;

      if (!x || !y) return;

      let pointData = {};
      if (this.mode === "pen") {

        this.context.moveTo(this.current?.x, this.current?.y);
        this.context.lineTo(
          x,
          y
        );
        this.context.stroke();

        this.current.x = x;
        this.current.y = y;

        pointData = {
          x: this.current?.x / w,
          y: this.current?.y / h,
          color: this.color,
          strokeWidth: this.strokeWidth,
          room: this.room,
        };
      } else if (this.mode === "eraser") {
        this.context.beginPath();
        this.context.globalCompositeOperation = "destination-out";
        this.context.arc(
          this.current.x,
          this.current.y,
          this.eraserRadius,
          0,
          Math.PI * 2,
          false
        );
        this.context.fill();

        this.current.x = x;
        this.current.y = y;

        pointData = {
          pathId: this.currentPath.id,
          x: this.current?.x / w,
          y: this.current?.y / h,
          radius: this.eraserRadius,
          erase: true,
        };

        eventBus.$emit("erase", pointData);

        whiteboardApi.erasing(pointData);
      }

      this.currentPath.points.push(pointData);
    },
    followCursor(e) {
      const cursor = this.$refs.cursor;
      if (cursor) {
        cursor.style.left = e.offsetX + "px";
        cursor.style.top = e.offsetY + "px";
      }
    },
    onExternalEraseEvent(data) {
      const w = this.canvas.width;
      const h = this.canvas.height;

      if (this.drawing || this.erasing) {
        this.handleMouseUp();
      }

      if (!this.externalErasing) {
        this.externalErasing = true;
        this.currentPath = { id: data.pathId, eraser: true, points: [] };
      }

      this.context.beginPath();
      this.context.globalCompositeOperation = "destination-out";
      this.context.arc(
        data.x * w,
        data.y * h,
        data.radius,
        0,
        Math.PI * 2,
        false
      );
      this.context.fill();

      const pointData = {
        pathId: data.pathId,
        x: data.x,
        y: data.y,
        radius: data.radius,
        erase: true,
      };

      this.currentPath.points.push(pointData);
    },
    onStopExternalErasingEvent() {
      if (this.externalErasing) {
        this.externalErasing = false;
        this.pathHistory.push(this.currentPath);
      }
    },
    drawPaths(paths, awaitAnimationFrame) {
      // clear canvas
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        const w = this.canvas.width;
        const h = this.canvas.height;

        for (let x = 0; x < path.points.length; x++) {
          const point = path.points[x];

          if (!point.erase) {
            if (x == 0) {
              this.context.beginPath();
              this.context.globalCompositeOperation = "source-over";
              this.context.strokeStyle = point.color;
              this.context.lineWidth = point.strokeWidth;
              this.context.lineJoin = "round";
              this.context.lineCap = "round";
            }

            this.context.lineTo(point.x * w, point.y * h);
            this.context.stroke();
          } else {
            this.context.beginPath();
            this.context.globalCompositeOperation = "destination-out";
            this.context.arc(
              point.x * w,
              point.y * h,
              point.radius,
              0,
              Math.PI * 2,
              false
            );
            this.context.fill();
          }
        }
      }

      if (awaitAnimationFrame) {
        this.getAnimationFrame().then(() => {
          this.loadingExistingPaths = false;
        });
      }
    },
    getAnimationFrame() {
      return new Promise(function (resolve) {
        requestAnimationFrame(resolve); // this promise never gets rejected
        // TODO: cancellation support :-)
      });
    },
    onNewPath(path) {
      const myPath = this.pathHistory.find(
        (existingPath) => existingPath.id === path.id
      );

      if (myPath) {
        // remove the path from the live board
        this.pathHistory = this.pathHistory.filter(
          (existingPath) => existingPath.id !== path.id
        );
        this.drawPaths(this.pathHistory);
      }
    },
  },
};
</script>