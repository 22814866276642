/*
SignalR ack and retry wrapper
*/
import axios from 'axios';

const ACK = 'ACK'
const retryQueue = [];
const defaultConfig = {
    maxRetries: 3,
    retryThresholdMs: 2000,
    endPoint: null
}

var globalConfig = null;

/* Example object delete later */
// exampleObj = {
//     id: 'GUID HERE',
//     payload: null,
//     timeout: null,
//     retries: 0,
//     config: null
// }

function createUuid() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0; dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    return uuid;
}

export default function (conn, config) {
    // clear queue
    clearQueue();

    // setup config
    if (config) {
        globalConfig = getConfig(config);
    }

    // setup reciciever
    conn.on(ACK, clearAck);
}

function clearQueue() {
    retryQueue.forEach(x => {
        clearTimeout(x.timeout);
        if (x.reject) {
            let err = Error("Queue was cleared before ACK was recieved");
            x.reject(err);
        }
    });

    while (retryQueue && retryQueue.length)
        retryQueue.pop();
}

async function ackSignalAsync(message, config = null){
    
    let requestConfig = getConfig(config);

    let request = {
        messageId: message.messageId,
        header: {
            messageType: ACK,
            recipientId: message.header.senderId,
            senderId: message.header.recipientId
        },
        payload: null
    }

    await axios.post(requestConfig.endPoint, request).catch((err) => {
      return Promise.reject(new Error(err));
    });
}

async function sendSignalAsync(header, payload, config = null, resolve = null, reject = null) {
  

    let requestConfig = getConfig(config);
    let requestId = createUuid();//v5();//

    // Request object which will be sent
    let request = {
        messageId: requestId,
        header: header,
        payload: payload
    }

    // ackObj used to track request
    let ackObj = {
        id: requestId,
        payload: request,
        retries: 0,
        config: requestConfig,
        timeout: null, // Timeout is attached later once request is dispatched,
        resolve: resolve,
        reject: reject
    }

    retryQueue.push(ackObj);

    waitAck(requestId, header.messageType, requestConfig);
}

function sendSignal(header, payload, config = null) {
    return new Promise((resolve, reject) => {
        sendSignalAsync(header, payload, config, resolve, reject);
    });
}

function clearAck(message) {
    // Find Ack by uuuid
    let { messageId } = message;

    let ackObj = retryQueue.find(x => x.id == messageId);
    if (ackObj) {
        let index = retryQueue.indexOf(ackObj);
        clearTimeout(ackObj.timeout);
        retryQueue.splice(index, 1);
        if (ackObj.resolve) {
            ackObj.resolve(message);
        }
    }
}

function getConfig(localConfig) {
    // get the base config
    let tmpConfig = defaultConfig;
    Object.assign(tmpConfig, globalConfig, localConfig);

    return tmpConfig;
}

function configIsValid(config) {
    // basic config validation
    if(!config) return false;
    if(!config.maxRetries || config.maxRetries <= 0) return false;
    if(!config.retryThresholdMs || config.retryThresholdMs <= 0) return false;
    if(!config.endPoint || !config.endPoint.length) return false;
   
    return true;
}

async function waitAck(messageId, messageType) {
    // check its still on the que
    let ackObj = retryQueue.find(x => x.id == messageId);

    if (!ackObj || !configIsValid(ackObj.config)) {
        return;
    } else if (ackObj.retries >= ackObj.config.maxRetries) {
        let err = Error("Max retries signalR limit has been reached for message type: " + messageType);
        if (ackObj.reject) {
            ackObj.reject(err);
            return;
        } else {
            throw err;
        }
    } else {
        // send payload
        await send(ackObj.config.endPoint, ackObj.payload).catch(err => { throw new Error(err) });

        ackObj.timeout = setTimeout(waitAck, ackObj.config.retryThresholdMs, messageId);
        ackObj.retries++;
    }
}


function send(endPoint, payload) {
    if(endPoint)
        return axios.post(endPoint, payload);
    return Promise.resolve;
}

function sendWithNoTracking(header, payload, config) {
    if(!config)
        config = getConfig();

    let requestId = createUuid();//.then();

    // Request object which will be sent
    let request = {

        messageId: requestId,
        header: header,
        payload: payload
    }

    return send(config.endPoint, request);
}



export {
    sendWithNoTracking,
    sendSignal,
    sendSignalAsync,
    ackSignalAsync,
    clearQueue
}
