import axios from "axios";

let baseUrl = "";
const whiteboardRoute = "api/whiteboard";
let socketId = "";
let room = "";

const configure = (baseUrlInput, socketIdInput, roomInput) => {
  baseUrl = baseUrlInput;
  socketId = socketIdInput;
  room = roomInput;
};

const getRequestObject = (method) => {
  return {
    socketId: socketId,
    method: method,
    room: room,
  };
};

const joinRoom = async (data) => {
  const request = getRequestObject("join-room");
  request.data = data;
  await axios.post(`${baseUrl}${whiteboardRoute}/join-room`, request);
};

const leaveRoom = async () => {
  const request = getRequestObject("leave-room");

  const headers = {
    type: "text/plain",
  };

  const blob = new Blob([JSON.stringify(request)], headers);

  if (window.navigator && window.navigator.sendBeacon) {
    window.navigator.sendBeacon(
      `${baseUrl}${whiteboardRoute}/leave-room`,
      blob
    );
  } else {
    await axios.post(`${baseUrl}${whiteboardRoute}/leave-room`, request);
  }
};

const newPath = async (data) => {
  const request = getRequestObject("new-path");
  request.data = data;
  await axios.post(`${baseUrl}${whiteboardRoute}/new-path`, request);
};

const clearRoom = async () => {
  const request = getRequestObject("clear-room");
  await axios.post(`${baseUrl}${whiteboardRoute}/clear-room`, request);
};

const erasing = async (data) => {
  const request = getRequestObject("erasing");
  request.data = data;
  await axios.post(`${baseUrl}${whiteboardRoute}/erasing`, request);
};

const resize = async (data) => {
  const request = getRequestObject("resize");
  request.data = data;
  await axios.post(`${baseUrl}${whiteboardRoute}/resize`, request);
};

const questionSnip = async (data) => {
  const request = getRequestObject("question-snip");
  request.data = data;
  await axios.post(`${baseUrl}${whiteboardRoute}/question-snip`, request);
};

export default {
  configure,
  joinRoom,
  leaveRoom,
  newPath,
  clearRoom,
  erasing,
  resize,
  questionSnip,
};
