export default function loadStudentAvatarProfile(preferences) {
    const { backgroundImage: background, theme } = preferences || {};
   
    if (!background) return;
    
    const container = document.querySelector("#app");
    container.style.backgroundImage = `url(${background})`;
    container.style.backgroundRepeat = 'no-repeat';
    container.style.backgroundSize = 'cover';
    
    const html = document.getElementById("html");
    html.setAttribute('class', html.getAttribute('class').replace(/theme--[a-z]+/, ''));
    html.classList.add(`theme--${theme}`);
    html.classList.forEach(cls => {
        if (cls.includes('skin')) {
            html.classList.remove(cls);
        }
    });
    
}