import { LEGACY_DEVICE_CHECKS, MATHS_GLOSSARY } from "@/constants/featureFlags";

export default {
  compass: {
    apiRoot: process.env.VUE_APP_COMPASS_API_ROOT,
    apiBase: process.env.VUE_APP_COMPASS_API_BASE,
    remoteEventsApiBase: "",
    cookieKeys: {
      centreWorkstation: "centre-workstation",
      homeAuthToken: "at-family",
      homeSysChecked: "system-checked",
      homeIndependentSysChecked: "independent-system-checked",
    },
    supportContacts: {
      phone: "01483 447414",
      phoneHours: "between 9am-6pm",
      email: "support@explorelearning.co.uk",
      url: "https://docs.google.com/forms/d/1_eOlfvPgY7BOTlW6j9MGLj94fpgxnm3EZ9EvDIrxy8g",
      contentIssueUrl:
        "https://docs.google.com/forms/d/e/1FAIpQLSe4DLoruELMrQpuuHDfGc1zEKCaFdmqwpIVL9kzxyn5xkNLmQ/viewform",
    },
  },

  restoreRoute: null,

  deviceHasTouch: null,

  mode: null,
  homeAuthToken: null,
  homeFamily: null,

  centre: null,
  centreZone: null,
  
  student: null,
  studentStartedAt: null,
  studentProfile: null,
  studentAvatar: null,
  skin: null,

  tutoredPanelEnabled: false,

  awardBalances: null,
  awardsGiven: {
    fearless_learner: false,
    overcome_challenges: false,
    understand_and_convince: false,
    respect: false,
  },
  fearlessPointsTotal: null,
  studentAvatarProfile: null,

  fourModalActive: false,
  session: null,

  activity: null,
  activityStartingSkills: null,
  activityStartingSkill: null,
  activityState: null,
  activityIsAssessmentMode: false,
  activitiesMeta: [],
  hasRemainingActivities: false,
  sessionHasAssessmentActivities: false,
  assessmentActivityIds: [],
  sessionEvents: [],
  lastSentSessionEventTimeStamp: null,
  networkSpeedDetails: {
    minimum: null,
    maximum: null,
    average: null,
  },
  autoSessionStartValues: null,
  tabInstanceId: null,
  tabInvalid: false,
  avatarsAndSkinsEnabled: true,
  accessibilityFeatures: {
    modalActive: false,
    fontSize: 1,
    colorOverlay: "none",
  },
  preventSaveToLocalStorage: false,
  returnRoute: null,
  avatarSelectorReturnRoute: null,

  selectedTopic: null,

  noticeLoadingText: "",

  lesson: null,
  scrollThroughQuestionsMeta: null,
  unrestrictedActivities: null,

  featureFlags: {
    [MATHS_GLOSSARY]: false,
    [LEGACY_DEVICE_CHECKS]: false,
  },

  currentQuestion: null,

  latestError: null,

  topicAutoStartConfig: null,
  studentAutoSelect: null,
  ssoRouteTo: null,
  useAlternativeSignalRConnectionString: false,
  
  selectedCurriculum: null,

  stepByStepState: null,
  tutorSessionType: null,

  gcseQuerySession: null,
  gcseAvailableSubjects: null,
  gcseSelectSkillSubject: null,
  gcseAvailableAreas: null,
  gcseSelectSkillArea: null,
  gcseSelectCurriculum: null,
  gcseAvailableSkills: null,
  gcseSelectSkill: null,

  gcseReturnedSkills: null,

  rumEnabled: false,
};
