import { errorMessages, errorMessageTypes } from "@/constants/errors";

export const showErrorNotification = function (errorMessage) {
  const errorsToIgnore = ["INDEXEDDB_ERR"];
  if (!errorsToIgnore.some((v) => errorMessage.includes(v))) {
    this.show = true;
  }

  this.errorType = getErrorType(errorMessage);
  this.errorMsg = errorMessage;

  let logError = "No error message available";

  if (errorMessage && errorMessage.length) {
    logError = JSON.stringify(this.errorMsg);
  }

  if (!errorsToIgnore.some((v) => errorMessage.includes(v))) {
    this.$store.dispatch("logSessionEvent", {
      logEvent: "Error notification modal dialog shown for error: " + logError,
      category: "AtHomeSession",
      verbosity: "Error",
    });
  } else {
    this.$store.dispatch("logSessionEvent", {
      logEvent:
        "Error notification modal dialog suppressed for error: " + logError,
      category: "AtHomeSession",
      verbosity: "Error",
    });
  }
};

const getErrorType = (errorMessage) => {
  const getMatchingInput = (matcher) => errorMessage.match(matcher)?.input;

  switch (errorMessage) {
    // Hardware unavailable
    case getMatchingInput(errorMessages.OT_HARDWARE_UNAVAILABLE):
      return errorMessageTypes.HARDWARE_UNAVAILABLE;

    // Hardware permission
    case getMatchingInput(errorMessages.OT_USER_MEDIA_ACCESS_DENIED):
    case getMatchingInput(errorMessages.GET_USER_MEDIA_NOT_ALLOWED):
      return errorMessageTypes.HARDWARE_NO_PERMISSION;

    // Network error
    case getMatchingInput(errorMessages.NETWORK_ERROR):
    case getMatchingInput(errorMessages.CHUNK_LOAD_ERROR):
      return errorMessageTypes.NETWORK_ERROR;

    // Other
    default:
      return errorMessageTypes.DEFAULT;
  }
};
