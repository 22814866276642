/**
 * Setup Axios for HTTP requests
 */
window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Setup Datadog logging
 */
import { default as logger, error } from "./utils/logger";
import { initialiseRum } from "./utils/rum";
import eventBus from "./utils/eventBus";
import store from "./core/store";

window.axios
  .get("/api/clientenviroment")
  .then((response) => {
    if (response.data && response.data.loggerConfig) {
      const { loggerConfig, serviceSettings } = response.data;
      store.state.env = loggerConfig.env;
      store.state.rumEnabled = false;
      store.state.navigator = serviceSettings?.navigator;
      logger(loggerConfig);
      initialiseRum();
    } else {
      throw new Error("No settings returned from API");
    }
  })
  .catch((err) => {
    /*eslint-disable */
    console.log(`Failed to init logger: ${err}`);
    /*eslint-enable */
  });

//window.axios.interceptors.response.use(
//    response => {

//        return Promise.resolve(response);
//    },
//    error => {
//        return Promise.reject(error);
//    }
//);

window.axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;

    // work as part of ELS-4300, a flow is causing auth to be cleared
    // in centre does not handle lack of auth well, so re-auth on a 401 in centre
    const inCentre = store?.state?.mode === "desktop-centre";
    if (status === 401 && inCentre) {
      const signInUrl = `${store?.getters?.compass?.apiBase}/auth/sign-in`;
      window.axios.post(signInUrl, {
        studentReference: store?.state?.student?.id,
        isCentreMode: store.state.mode === "desktop-centre",
      });
      return;
    }
    return Promise.reject(error);
  }
);

/**
 * VUE: Import
 */
import { defineAsyncComponent } from "vue";
import app from "./core/app";
import VueGtag from "vue-gtag";

/**
 * VUE: Init
 */

app.use(router).use(store).use(VueGtag, {
  config: { id: "G-CW960HD49T" }
}).provide("groupSession", null);

/**
 * VUE: Mixins (helpers)
 */
import mixins from "./core/mixins.js";
app.mixin({
  methods: mixins,
});

/**
 * VUE: Directives
 */
import directives from "./core/directives";
directives.forEach((dir) => {
  app.directive(dir.name, dir.config);
});

/**
 * VUE: Components
 */
app.component(
  "accordion",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--Accordion" */ "./components/Accordion"
    )
  )
);
app.component(
  "activity-cards",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--ActivityCards" */ "./components/ActivityCards"
    )
  )
);
app.component(
  "award-modal",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--AwardModal" */ "./components/AwardModal"
    )
  )
);
app.component(
  "chart-pie",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--ChartPie" */ "./components/ChartPie"
    )
  )
);
app.component(
  "el-header",
  defineAsyncComponent(() =>
    import(/* webpackChunkName: "components--Header" */ "./components/Header")
  )
);
app.component(
  "notice",
  defineAsyncComponent(() =>
    import(/* webpackChunkName: "components--Notice" */ "./components/Notice")
  )
);
app.component(
  "notice-loading",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--NoticeLoading" */ "./components/NoticeLoading"
    )
  )
);
app.component(
  "profile-card",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--ProfileCard" */ "./components/ProfileCard"
    )
  )
);
app.component(
  "tutor-modal",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--TutorModal" */ "./components/TutorModal"
    )
  )
);
app.component(
  "four-modal",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--FourModal" */ "./components/FourModal"
    )
  )
);
app.component(
  "tutor-pin",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--TutorPin" */ "./components/TutorPin"
    )
  )
);
app.component(
  "tutored-panel",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--TutoredPanel" */ "./components/TutoredPanel"
    )
  )
);
app.component(
  "simulated-tutored-panel",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--SimulatedTutoredPanel" */ "./components/SimulatedTutoredPanel"
    )
  )
);
app.component(
  "ImgSmooth",
  defineAsyncComponent(() =>
    import(/* webpackChunkName: "utils--ImgSmooth" */ "./utils/ImgSmooth")
  )
);
app.component(
  "NetworkResult",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--NetworkResult" */ "./components/NetworkResult"
    )
  )
);
app.component(
  "web-link",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--NoticeLoading" */ "./components/WebLink"
    )
  )
);
app.component(
  "error-notification",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--ErrorNotifcation" */ "./components/ErrorNotifcation"
    )
  )
);
app.component(
  "ManagerObservingNotification",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--ManagerObservingNotification" */ "./components/ManagerObservingNotification"
    )
  )
);
app.component(
  "CompassEngineQuestion",
  defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "components--CompassEngineQuestion" */ "./components/question-templates/CompassEngineQuestion"
    )
  )
);

/**
 * VUE: Routing
 */
import router from "./core/router";
import { skipPostSessionErrorMessages } from "./constants/errors";

/**
 * Error Handlers
 */
app.config.errorHandler = (err, vm, info) => {
  store.dispatch("setLatestError", err);
  error(err.message);
  /*eslint-disable */
  console.log("===== EXPLORE LEARNING STUDENT DESKTOP ERROR =====");
  console.log("Error", err);
  console.log("Vue Component / Instance", vm);
  console.log("Vue Info", info);
  var errMessage = "* SOMETHING WENT WRONG *\n";

  if (err.msg && err.msg > 0) {
    errMessage += err.msg;
  } else {
    errMessage += "Vue Error Handler: " + err.toString();
  }

  errMessage += "\n" + info;
  var e = new Error();
  errMessage += "\n" + e.stack;

  emitErrorMessage(errMessage);
};

window.onerror = function (message, source, lineno, colno, err) {
  /*eslint-disable */
  console.log("===== EXPLORE LEARNING STUDENT DESKTOP ERROR =====");
  console.log("Message", message);
  console.log("Source", source);
  console.log("Line Number", lineno);
  console.log("Column Number", colno);
  console.log("Error", error);
  var errMessage = "* SOMETHING WENT WRONG *\n";
  if (message && message.length > 0) {
    errMessage += message;
  } else {
    errMessage += "Window on error: " + error.toString();
  }

  errMessage += "\n" + source;
  var e = new Error();
  errMessage += "\n" + e.stack;

  emitErrorMessage(errMessage);
};

window.addEventListener("unhandledrejection", function (event) {
  store.dispatch("setLatestError", event.reason);
  const routeName = router.currentRoute.value.name;
  if (app && routeName && routeName !== "Desktop.Home.SystemCheck") {
    /*eslint-disable */
    console.log("===== EXPLORE LEARNING STUDENT DESKTOP ERROR =====");
    console.log("Event", event);

    var errMessage = "* SOMETHING WENT WRONG *\n";
    if (event.reason && event.reason.length > 0) {
      errMessage += event.reason;
      errMessage += event.promise;
    } else {
      errMessage += "Unhandled Rejection: " + event.promise;
      errMessage += "\nReason: " + event.reason;
    }

    var e = new Error();
    errMessage += "\n" + e.stack;

    emitErrorMessage(errMessage);
  }
});

export const emitErrorMessage = (errorMessage) => {
  const shouldEmit = shouldEmitErrorMessage(errorMessage);

  if (shouldEmit === true) {
    /*eslint-enable */
    eventBus.$emit("show-error-notification", errorMessage);
  }
};

const shouldEmitErrorMessage = (errorMessage) => {
  // if errorMessage is included in any of the pre-defined skipPostSessionErrorMessages
  //  return false
  return (
    skipPostSessionErrorMessages.some((skipMessage) =>
      errorMessage.includes(skipMessage)
    ) === false
  );
};

app.mount("#app");
