export const clickOutside = {
  name: "click-outside",
  config: {
    mounted(el, binding) {
      el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    },
  },
};

export const focus = {
  name: "focus",
  config: {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
      // Focus the element
      el.focus();
    },
  },
};

export default [clickOutside, focus];
