import store from "@/core/store";
import { datadogLogs } from "@datadog/browser-logs";
import { flattenErrorCauses } from "@datadog/browser-core/cjs/tools/error";
import { LogLevel as SignalRLogLevel } from "@microsoft/signalr";
import router from "@/core/router";

// General config
const logLevels = ["error", "warn", "info", "debug"];

let config = {
  currentEnv: "debug",
  consoleLevel: "debug",
  consoleEnv: ["prod", "debug", "staging", "uat", "test"],
  ddLevel: "info",
  ddEnv: ["prod", "debug", "staging", "uat", "test"],
  ddConfig: {
    clientToken: "pubccaa9e2a1b13b7db9aeda885e02c6918",
    site: "datadoghq.eu",
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: "debug",
    service: "student-desktop-client",
  },
};

var isInSlowMode = false;

function setIsSlowMode(isSlowMode) {
  isInSlowMode = isSlowMode;
}

function isLoggingLevel(setLevel, level) {
  let setLevelIndex = logLevels.indexOf(setLevel);
  let levelIndex = logLevels.indexOf(level);

  return levelIndex <= setLevelIndex;
}

function isLoggingEnv(loggingEnv, currentEnv) {
  return loggingEnv.includes(currentEnv);
}

function error(message) {
  ddLog(message, "error");

  consoleLog(message, "error");
}

function info(message) {
  ddLog(message, "info");

  consoleLog(message, "info");
}

function warn(message) {
  ddLog(message, "warn");

  consoleLog(message, "warn");
}

function debug(message) {
  ddLog(message, "debug");

  consoleLog(message, "debug");
}

function consoleLog(message, level) {
  if (
    !isLoggingLevel(config.consoleLevel, level) &&
    isLoggingEnv(config.consoleEnv, config.currentEnv)
  ) {
    return;
  }
  /*eslint-disable */
  console.log(message);
  /*eslint-enable */
}

function ddLog(message, level) {
  if (isInSlowMode) return;

  if (
    !isLoggingLevel(config.ddLevel, level) &&
    isLoggingEnv(config.ddEnv, config.currentEnv)
  ) {
    return;
  }

  const ddLogger = datadogLogs.logger;

  switch (level) {
    case "info":
      ddLogger.info(message);
      break;
    case "warn":
      ddLogger.warn(message);
      break;
    case "debug":
      ddLogger.debug(message);
      break;
    case "error":
      ddLogger.error(message);
      break;
    default:
  }
}

const scrubIfAxiosError = (error) => {
  return error.name !== "AxiosError"
    ? error
    : {
        ...error,
        response: null,
        config: {
          data: error.config.data,
          url: error.config.url,
        },
      };
};

const beforeSend = (log) => {
  try {
    // add store info
    const session = store.state.session;
    const currentUser = {
      tutorSessionId: session?.tutor
        ? store.state.session.tutor.tutorSessionId
        : null,
      familyReference: store.state.homeFamily
        ? store.state.homeFamily.familyReference
        : null,
      networkSpeed: store.state.networkSpeed
        ? store.state.networkSpeed.toString()
        : null,
      userAgent: store.state.userAgent ? store.state.userAgent : null,
      device: store.state.isTablet ? "Tablet" : "Desktop",
      studentReference: session?.studentReference,
      sessionId: session?.id,
      sessionType: session?.sessionType,
      currentRoute: router?.currentRoute?.value?.name,
    };

    log.usr = currentUser;

    // improve error logs
    if (log.error) {
      const latestError = store.state.latestError;
      const scrubbedError = scrubIfAxiosError(latestError);

      log.error = {
        ...log.error,
        ...scrubbedError,
        stack: log.error.stack ? log.error.stack : scrubbedError.stack,
        message: log.error.message
          ? log.error.message
          : log.message
          ? log.message
          : scrubbedError.message,
      };

      if (latestError.cause) {
        log.error.causes = flattenErrorCauses(latestError).map((cause) => ({
          ...cause,
          source: "source",
        }));
      }
    }
  } catch {
    // Precautionary defensive code to ensure logging doesn't ever cause uncaught exception & infinite loop.
    return;
  }
};

class SignalRLogger {
  constructor(obj) {
    this.isWhiteboard = obj?.isWhiteboard ? true : false;
  }

  log(logLevel, message) {
    const levels = [
      "Trace",
      "Debug",
      "Information",
      "Warning",
      "Error",
      "Critical",
      "None",
    ];
    // Use `message` and `logLevel` to record the log message to your own system
    if (logLevel >= SignalRLogLevel.Debug) {
      if (logLevel === SignalRLogLevel.Error) {
        error(
          `[${new Date().toISOString()}] ${
            this.isWhiteboard ? "Whiteboard SignalR" : "SignalR"
          } ${levels[logLevel]}: ${message}`
        );
      } else if (logLevel === SignalRLogLevel.Warning) {
        warn(
          `[${new Date().toISOString()}] ${
            this.isWhiteboard ? "Whiteboard SignalR" : "SignalR"
          } ${levels[logLevel]}: ${message}`
        );
      } else {
        info(
          `[${new Date().toISOString()}] ${
            this.isWhiteboard ? "Whiteboard SignalR" : "SignalR"
          } ${levels[logLevel]}: ${message}`
        );
      }
    }
  }
}

export default function (customConfig) {
  const mergedConfig = {
    ...config,
    ...customConfig,
  };

  mergedConfig.ddConfig.beforeSend = beforeSend;
  datadogLogs.init(mergedConfig.ddConfig);
}

export { error, info, debug, warn, consoleLog, setIsSlowMode, SignalRLogger };
