<template>
  <div v-if="enabled" class="whiteboard">
    <div class="whiteboard__frame" :style="{
      left: `${this.frameLeftPosition}px`,
      width: `${this.frameWidth}px`,
      height: `${this.frameHeight}px`,
    }">
      <button @click.prevent="onClose" class="whiteboard__close">
        <span>Close</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <Controls :mode="mode" :color="color" :strokeWidth="strokeWidth" :eraserRadius="eraserRadius"
        v-on:change-mode="changeMode" v-on:change-color="changeColor" v-on:change-stroke-width="changeStrokeWidth"
        v-on:change-eraser-radius="changeEraserRadius" />
      <LiveBoard :mode="mode" :color="color" :strokeWidth="strokeWidth" :eraserRadius="eraserRadius" />
      <CommittedBoard />

      <QuestionSnip />
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/components/whiteboard/whiteboard";
</style>

<script>
import Controls from "./Controls.vue";
import axios from "axios";
import eventBus from "@/utils/eventBus";
import LiveBoard from "./LiveBoard.vue";
import CommittedBoard from "./CommittedBoard.vue";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { SignalRLogger } from "@/utils/logger";
import whiteboardApi from "@/api/whiteboard.js";
import { sendWithNoTracking } from "@/utils/signalAck";
import QuestionSnip from "./QuestionSnip.vue";
import { TUTORED_PANEL_DOCKED } from "@/constants/events";
import { DESKTOP_SESSION_ACTIVITY_SCROLL_THROUGH } from "@/constants/routes";
import { error } from "@/utils/logger";

export default {
  name: "Whiteboard",
  components: {
    LiveBoard,
    CommittedBoard,
    Controls,
    QuestionSnip,
  },
  data() {
    return {
      enabled: false,
      signalR: null,
      mode: "pen",
      color: "black",
      strokeWidth: 5,
      eraserRadius: 50,
      frameLeftPosition: 0,
      maxWidth: 0,
      maxHeight: 0,
      serverWidth: 0,
      serverHeight: 0,
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    remoteEventsURL() {
      return this.$store.state.remoteEventsSettings.url;
    },
    frameWidth() {
      return this.serverWidth !== 0 ? this.serverWidth : this.maxWidth;
    },
    frameHeight() {
      return this.serverHeight !== 0 ? this.serverHeight : this.maxHeight;
    },
    tutoredPanelDockedList() {
      return [
        'Desktop.Session.Activity',
        DESKTOP_SESSION_ACTIVITY_SCROLL_THROUGH
      ]
    },
    useAlternativeSignalRConnectionString() {
      return this.$store.state.useAlternativeSignalRConnectionString;
    },
    isBeingTutored() {
      return this.$store.state.isBeingTutored;
    }
  },
  mounted() {
    eventBus.$on("enable-whiteboard", this.onOpen);
    eventBus.$on("question-snip-request", this.questionSnip);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize, false);
    eventBus.$off("enable-whiteboard", this.onOpen);
    eventBus.$off("question-snip-request", this.questionSnip);
    this.onClose();
  },
  methods: {
    preventDefault(e) {
      e.preventDefault();
    },
    onResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
      this.resizeTimeout = setTimeout(() => {
        this.sizeCanvas(true);
      }, 500);
    },
    onOpen() {
      if (!this.enabled) {
        document.body.addEventListener("touchmove", this.preventDefault, {
          passive: false,
        });
        window.addEventListener("resize", this.onResize, false);
        this.enabled = true;
        eventBus.$emit(TUTORED_PANEL_DOCKED, true);
        this.sizeCanvas();

        if (!this.signalR) {
          this.signalRConnect();
        }
      }
    },
    onClose() {
      if (this.enabled) {
        document.body.removeEventListener("touchmove", this.preventDefault);
        window.removeEventListener("resize", this.onResize, false);
        this.enabled = false;
        this.signalRDisconnect();

        if (!this.tutoredPanelDockedList.includes(this.$route.name)) {
          eventBus.$emit(TUTORED_PANEL_DOCKED, false);
        }

        const header = {
          messageType: "close-whiteboard",
          senderId: this.session.id,
          recipientId: this.session.tutor.tutorSessionId,
        };

        sendWithNoTracking(header);
      }
    },
    sizeCanvas(sendSizeMessage) {
      const tutorPanel = document.getElementById("tutored-panel");

      if (tutorPanel) {
        const tutorPanelWidth = tutorPanel.clientWidth;

        this.frameLeftPosition = tutorPanelWidth + 10;
      } else {
        this.frameLeftPosition = 10;
      }

      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      this.maxWidth = screenWidth - this.frameLeftPosition - 20;
      this.maxHeight = screenHeight - 100;

      if (sendSizeMessage) {
        whiteboardApi.resize({
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
        });
      }
    },
    async negotiate() {
      const res = await axios.post(this.elApiUrlRoot(`/signalr/negotiate`), {
        userId: this.session.id,
        hubName: 'whiteboard',
        useAlternativeConnectionString: this.useAlternativeSignalRConnectionString,
      });

      if (res.data) {
        return res.data;
      } else {
        throw "No data";
      }
    },
    async signalRConnect() {
      try {
        const connectionInfo = await this.negotiate();

        this.signalR = new HubConnectionBuilder()
          .withUrl(connectionInfo.url, {
            accessTokenFactory: async () => {
              try {
                const data = await this.negotiate();

                if (!data && !data.accessToken) {
                  return null;
                }

                return data.accessToken;
              } catch (err) {
                error(`Error in accessTokenFactory for whiteboard hub with userId: ${this.session.id} and useAlternativeConnectionString: ${this.useAlternativeSignalRConnectionString}`, { cause: error })
                throw new Error(err);
              }
            },
          })
          .configureLogging(new SignalRLogger({ isWhiteboard: true }))
          .withAutomaticReconnect()
          .build();

        // on reconnect, reset the connectionId
        this.signalR.onreconnected((connectionId) => {
          whiteboardApi.configure(
            this.remoteEventsURL,
            connectionId,
            this.session.id
          );

          whiteboardApi.joinRoom({
            maxWidth: this.maxWidth,
            maxHeight: this.maxHeight,
          });
        })

        this.signalR.on("user-connected", (socketId, message) => {
          const parsedMessage = JSON.parse(message);

          if (parsedMessage.screenSize) {
            this.serverWidth = parsedMessage.screenSize.maxWidth;
            this.serverHeight = parsedMessage.screenSize.maxHeight;

            setTimeout(() => {
              eventBus.$emit("resize-canvas");
            }, 100);
          }
        });

        this.signalR.on("resize", (socketId, message) => {
          const parsedMessage = JSON.parse(message);

          if (parsedMessage.screenSize) {
            this.serverWidth = parsedMessage.screenSize.maxWidth;
            this.serverHeight = parsedMessage.screenSize.maxHeight;

            setTimeout(() => {
              eventBus.$emit("resize-canvas");
            }, 100);
          }
        });

        this.signalR.on("new-path", (socketId, message) => {
          const parsedMessage = JSON.parse(message);

          if (parsedMessage) {
            if (parsedMessage.eraser) {
              eventBus.$emit("stop-external-erasing");
            } else {
              eventBus.$emit("new-path", parsedMessage);
            }
          }
        });

        this.signalR.on("erasing", (socketId, message) => {
          const parsedMessage = JSON.parse(message);

          if (parsedMessage) {
            eventBus.$emit("external-erasing", parsedMessage);
          }
        });

        this.signalR.on("existing-paths", (message) => {
          const parsedMessage = JSON.parse(message);

          if (parsedMessage) {
            eventBus.$emit("existing-paths", parsedMessage);
          }
        });

        this.signalR.on("existing-snip", (message) => {
          const parsedMessage = JSON.parse(message);

          if (parsedMessage) {
            eventBus.$emit("question-snip", parsedMessage);
          }
        });

        this.signalR.on("clear-room", () => {
          eventBus.$emit("clear-whiteboard");
        });

        this.signalR.on("question-snip-request", () => {
          this.questionSnip();
        });

        await this.signalR.start();

        whiteboardApi.configure(
          this.remoteEventsURL,
          this.signalR.connection.connectionId,
          this.session.id
        );

        await whiteboardApi.joinRoom({
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
        });
      } catch (err) {
        throw new Error(`Unable to connect to SignalR for whiteboard`, { cause: err })
      }
    },
    async signalRDisconnect() {
      if (this.signalR) {
        await whiteboardApi.leaveRoom();
        this.signalR.stop();
        this.signalR = null;
      }
    },
    changeMode(mode) {
      this.mode = mode;
    },
    changeColor(color) {
      this.color = color;
    },
    changeStrokeWidth(strokeWidth) {
      this.strokeWidth = strokeWidth;
    },
    changeEraserRadius(radius) {
      this.eraserRadius = radius;
    },
    async questionSnip() {
      let stage = document.querySelector(".cqt-stage");
      if (!stage) {
        stage = document.querySelector("#el-lrn-stage");
      }

      if (stage) {
        await whiteboardApi.clearRoom();
        eventBus.$emit("clear-whiteboard");
        eventBus.$emit("snipping-timeout");
        return this.snipIframe();
      }
    },
    snipIframe() {
      // clone the stage and set sizes
      const canvas = document.querySelector(".activity__lrn").cloneNode(true);
      const isTextBlocks =
        document.querySelector(".activity__text-blocks")?.getBoundingClientRect()
          .width > 0;

      // check if element with class of .cqt-stage exists
      let stage = document.querySelector(".cqt-stage");
      if (!stage) {
        stage = document.querySelector("#el-lrn-stage");
      }

      const stageWidth = !isTextBlocks
        ? stage.getBoundingClientRect().width
        : document
          .querySelector(".activity__text-blocks")
          .getBoundingClientRect().width;

      const stageHeight = !isTextBlocks
        ? stage.getBoundingClientRect().height
        : document
          .querySelector(".activity__text-blocks")
          .getBoundingClientRect().height;

      canvas.style.overflow = "hidden";
      const scrollTop = !isTextBlocks
        ? stage.scrollTop
        : document.querySelector(".activity__lrn").scrollTop;

      // clone the html and remove body html
      const canvasClone = canvas.cloneNode(true);

      // remove the stage if it's a text block snip
      if (isTextBlocks) {
        let lrnStage = canvasClone.querySelector(".cqt-stage");
        if (!lrnStage) {
          lrnStage = canvasClone.querySelector("#el-lrn-stage");
        }

        if (lrnStage) lrnStage.parentNode.removeChild(lrnStage);
      }

      canvasClone.style.width = `${stageWidth}px`;
      canvasClone.style.height = `${stageHeight}px`;
      const htmlCopy = document.cloneNode(true);
      const body = htmlCopy.querySelector("body");
      body.style.backgroundColor = "#fff";
      body.style.overflow = "hidden";
      body.style.display = "flex";
      body.style.justifyContent = "center";
      body.style.alignItems = "center";
      body.prepend(canvasClone);

      // remove app
      const app = htmlCopy.querySelector("#app");
      app.parentNode.removeChild(app);

      // remove scripts
      const scripts = htmlCopy.querySelectorAll("script");
      scripts.forEach((script) => {
        script.parentNode.removeChild(script);
      });

      // remove preloads
      const links = htmlCopy.querySelectorAll("link");
      links.forEach((link) => {
        if (!link.outerHTML.includes(".css")) {
          return link.parentNode.removeChild(link);
        }

        const href = link.getAttribute("href");
        if (href && !href.includes("http")) {
          if (
            !link.outerHTML.includes("app.") &&
            !link.outerHTML.includes("--Activity.") &&
            !link.outerHTML.includes("--CompassEngineQuestion.")
          ) {
            return link.parentNode.removeChild(link);
          }

          const newHref = `${window.location.protocol}//${window.location.hostname}${href}`;
          link.setAttribute("href", newHref);
        }
      });

      // remove iframes
      const iframes = htmlCopy.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        iframe.parentNode.removeChild(iframe);
      });

      // remove unneeded style tags
      // const styles = htmlCopy.querySelectorAll("style");
      // styles.forEach((style) => {
      //   if (!style.innerHTML.includes("lrn")) {
      //     style.parentNode.removeChild(style);
      //   }
      // });

      // broken html fix
      const lrnTextInputs = htmlCopy.querySelectorAll(".lrn_textinput");
      lrnTextInputs.forEach((textInput) => {
        const pTag = textInput.closest("p");
        if (pTag) {
          const innerContent = pTag.innerHTML;
          const newDiv = document.createElement("div");
          newDiv.innerHTML = innerContent;
          pTag?.parentNode?.replaceChild(newDiv, pTag);
        }
      });

      // map select values
      // select values are not pulled across during a clone
      const selectValues = document.querySelectorAll("select");
      selectValues.forEach((select) => {
        const inputId = select.getAttribute("data-inputid");
        if (inputId) {
          const copySelect = htmlCopy.querySelector(
            `select[data-inputid="${inputId}"]`
          );
          if (copySelect)
            copySelect
              .querySelector(`option[value="${select.value}"]`)
              ?.setAttribute("selected", "selected");
        }
      });

      // map through radio / checkbox inputs
      const inputs = document.querySelectorAll("input");
      inputs.forEach((input) => {
        let copyInput = htmlCopy.getElementById(input.id);
        if (!copyInput) {
          const inputId = input.getAttribute("data-inputid");
          copyInput = htmlCopy.querySelector(
            `input[data-inputid="${inputId}"]`
          );
        }

        if (copyInput) {
          if (input.checked) {
            copyInput.setAttribute("checked", "checked");
          }

          if (input.value) {
            copyInput.setAttribute("value", input.value);
          }
        }
      });

      // create and send the data
      const data = {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
        stageWidth,
        stageHeight,
        scrollTop,
        html: htmlCopy.documentElement.outerHTML,
      };

      eventBus.$emit("question-snip", data);
      whiteboardApi.questionSnip(data);
    },
  },
  watch: {
    $route(to) {
      const closeRoutes = [
        "Desktop.Session.GroupMode",
        "Desktop.Session.SessionCompleted",
      ];
      if (closeRoutes.includes(to.name)) {
        this.onClose();
      }
    },
    isBeingTutored(newValue, oldValue) {
      if (this.enabled && newValue && newValue !== oldValue) {
        // this handles the case where a tutor might disconnect when the whiteboard is open on the students side, it will open the whiteboard for the tutor again
        const header = {
          messageType: 'enable-whiteboard',
          senderId: this.$store.state.session?.id,
          recipientId: this.$store.state.session?.tutor?.tutorSessionId
        }

        sendWithNoTracking(header, {});
      }
    }
  },
};
</script>