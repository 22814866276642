export default {
  getBaseUrl: function () {
    let url = window.location.href;
    let urlParts = url.split("/");
    return urlParts[0] + "//" + urlParts[2];
  },

  getMode: function () {
    return this.$store.getters.mode;
  },

  modeIsDesktopCentre: function () {
    return this.getMode() === "desktop-centre";
  },

  modeIsDesktopHome: function () {
    return this.getMode() === "desktop-home";
  },

  imgSrc: function (filename) {
    return require(`@/assets/img/${filename}`);
  },

  imgSrcSkinned: function (filename) {
    let skin = this.$store.getters.skin;
    
    if (!skin || !skin.length) {
      skin = "SKIN1";
    }
    return require(`@/assets/img/skins/${skin}/${filename}`);
  },
  
  imgSrcThemed: function (filename) {    
    let theme = this.$store.getters.studentAvatarProfile.theme;
    
    if (!theme || !theme.length) {
      theme = "light";
    }
    
    return require(`@/assets/img/themes/${theme}/${filename}`);
  },

  elApiUrl: function (endpoint) {
    return this.$store.getters.compass.apiBase + endpoint;
  },

  elApiUrlRoot: function (endpoint) {
    return this.$store.getters.compass.apiRoot + endpoint;
  },

  elRemoteEventsApiSettings: function () {
    return this.$store.state.remoteEventsSettings;
  },

  awardBalance(currency) {
    let awardBalances = this.$store.getters.awardBalances;

    if (awardBalances === null) {
      return 0;
    }

    let filtered = awardBalances.filter((awardBalance) => {
      if (awardBalance.awardCurrencyId === currency) {
        return true;
      }
    });

    if (filtered && filtered.length === 1) {
      return filtered[0].balance;
    }

    return 0;
  },

  awardBalanceFearlessLearner: function () {
    return this.awardBalance("fearless_learner");
  },

  awardBalanceOvercomeChallenges: function () {
    return this.awardBalance("overcome_challenges");
  },

  awardBalanceUnderstandAndConvince: function () {
    return this.awardBalance("understand_and_convince");
  },

  awardBalanceRespect: function () {
    return this.awardBalance("respect");
  },

  awardBalanceLizardCoins: function () {
    return this.awardBalance("lizard_coin");
  },

  minutesToHoursAndMinutes(totalMinutes) {
    let hours = totalMinutes / 60;
    let hoursInt = Math.floor(hours);
    let minutes = (hours - hoursInt) * 60;
    let minutesInt = Math.round(minutes);

    return {
      hours: hoursInt,
      minutes: minutesInt,
    };
  },

  skillStatusesMastered() {
    return ["Mastered"];
  },

  skillStatusesLooselyMastered() {
    return ["Mastered", "Retained"];
  },

  skillStatusesLooselyUpcoming() {
    return ["New", "InProgress", "AtRisk", "NotMastered", "NotRetained"];
  },

  activityRatingOpts() {
    return [
      {
        value: 10,
        style: "good",
      },
      {
        value: 0,
        style: "neutral",
      },
      {
        value: -10,
        style: "bad",
      },
    ];
  },

  pluckAudioFileUrl(audioFiles) {
    if (!audioFiles.length) {
      return null;
    }

    let selectedVoices = audioFiles.slice().filter((audioFile) => {
      return !!(
        audioFile &&
        audioFile.voiceId &&
        audioFile.voiceId === "en-us-jessaneural"
      );
    });

    if (
      selectedVoices.length &&
      selectedVoices[0].url &&
      selectedVoices[0].url.length
    ) {
      return selectedVoices[0].url;
    }

    if (audioFiles.length && audioFiles[0].url && audioFiles[0].url.length) {
      return audioFiles[0].url;
    }

    return null;
  },

  quifixApply: function (tags) {
    this.quifixClear();

    if (!Array.isArray(tags) || !tags.length) return;

    let $html = document.getElementById("html");

    tags.forEach(function (tag) {
      $html.classList.add("quifix--" + tag);
    });
  },

  quifixClear: function () {
    let $html = document.getElementById("html");

    let quifixClasses = [];

    $html.classList.forEach((className) => {
      if (className.startsWith("quifix--")) {
        quifixClasses.push(className);
      }
    });

    if (!quifixClasses.length) return;

    $html.classList.remove(...quifixClasses);
  },

  sanitisedSessionActivities: function () {
    let session = this.$store.getters.session;

    if (!session || !session.activities || !session.activities.length) {
      return [];
    }

    return session.activities.slice();
  },
};
