<template>
    <notice :level="'loading'" :title="title" :message="message" :rrIgnore="rrIgnore" />
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: "Please wait"
            },
            message: {
                type: String,
                default: ""
            },
            rrIgnore: {
                type: Boolean,
                default: false
            }
        },
    };
</script>
