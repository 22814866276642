<template>
  <img
    v-bind="$attrs"
    :src="
      colour === 'dark' ? imgSrc('logo-dark.svg') : imgSrc('logo-light.svg')
    "
    :class="customClass ? customClass : ''"
    alt="Explore Learning Logo"
  />
</template>

<script>
export default {
  name: "Logo",
  props: ["colourOverride", "customClass"],
  computed: {
    skin() {
      return this.$store.state.skin;
    },
    theme(){
      return this.$store.state.studentAvatarProfile?.theme
    },
    colour() {
      if (this.colourOverride) {
        if (this.colourOverride === "dark") {
          return "dark";
        } else {
          return "light";
        }
      }

      const { skin, theme } = this;

      

      // const lightOptions = ["SKIN2", "SKIN4"];
      const darkOptions = ["jungle", "SKIN1", "SKIN3", "SKIN5"];
      //if theme exists, use this

      if (theme === 'dark') {
        return 'dark';
      } else if (theme === 'light') {
        return 'light';
      }
      
      //if theme doesn't exist (or is null) then use skin
      if (skin && darkOptions.includes(skin)) {
        return "dark";
      } else if (skin) {
        return "light";
      }
      
      return 'light';
    },
  },
};
</script>