// a list of family references that are allowed to use RUM
// set to "all" to allow all users to use RUM

export default {
  prod: [
    "EXP3290759", // ELS-4384
    "LUT00184029", // ELS-4386
    "EXP3307928", // ELS-4411
    "HOR00267617", // ELS-4422
    "EXP5216263", // ELS-4422
    "E085211919", // ELS-4422
    "E085217285", // ELS-4422
    "E085225443", // ELS-4422
    "E085238629", // ELS-4422
  ],
  staging: ["EXP3256835"],
  uat: "all",
  test: "all",
  debug: ["BOU3136720"],
};
