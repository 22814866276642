import { datadogRum } from "@datadog/browser-rum";
import store from "@/core/store";
import router from "@/core/router";
import rumUsers from "@/constants/rumUsers";
import { info } from "./logger";
import { SET_RUM_ENABLED } from "@/constants/actions";

const initialiseRum = () => {
  const env = store.state.env;

  // if rum already enabled, return
  if (store.state.rumEnabled) {
    return;
  }

  // ensure that the family is allowed to use RUM
  if (
    rumUsers[env] !== "all" &&
    !rumUsers[env]?.includes(store?.state?.homeFamily?.familyReference)
  ) {
    return;
  }

  datadogRum.init({
    applicationId: "f0d0d0b1-be15-40db-b2b1-f2288bb183a4",
    clientToken: "pubb4fba8d35e9fe007e769989051b3493c",
    site: "datadoghq.eu",
    service: "student-desktop",
    env: env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    beforeSend: (event) => {
      updateUserInfo();

      // add the current route name to the URL for debugging
      event.view.url = `${event.view.url} ${
        router?.currentRoute?.value?.name ?? ""
      }`;
    },
  });

  datadogRum.startSessionReplayRecording();
  // set rum enabled
  info("RUM initialised");
  store.dispatch(SET_RUM_ENABLED, true);
};

const updateUserInfo = () => {
  const user = {
    id: store?.state?.student?.id,
    studentReference: store?.state?.student?.id,
    curriculumType: store?.state?.student?.curriculumType,
    familyReference: store?.state?.homeFamily?.familyReference,
  };

  return datadogRum.setUser(user);
};

export { initialiseRum };
