import axios from "axios";

export const createSession = async (store) => {
  const currentMode = store.state.mode;

  const sessionType =
    currentMode === "desktop-centre"
      ? "Centre"
      : currentMode === "desktop-home"
      ? "Independent" // TODO: What about tutored sessions?
      : null;

  if (sessionType === null) {
    throw new Error("Unrecognised / unexpected mode: " + currentMode);
  }

  const centreId =
    store.state.centre !== null
      ? store.state.centre.id
      : store.state.homeFamily !== null
      ? store.state.homeFamily.centreId
      : null;

  const zoneId = store.state.centreZone?.id || "---";

  const studentReference = store.state.student.id;

  return axios.post(store.state.compass.apiBase + "/sessions", {
    centreId,
    zoneId,
    sessionType,
    studentReference,
  });
};

export const getCurrentSession = async (store) => {
  if (!store.state.session || !store.state.session.id) {
    throw new Error("No current session");
  }

  const session = await axios.get(
    `${store.state.compass.apiBase}/sessions/${store.state.session?.id}`
  );

  return session.data;
};

export const abandonSession = async (store) => {
  if (!store.state.session || !store.state.session.id) {
    throw new Error("No current session");
  }

  return axios.put(
    `${store.state.compass.apiBase}/sessions/${store.state.session?.id}/abandon`
  );
};
