// import Vue from "vue";
import { createRouter, createMemoryHistory } from "vue-router";
import store from "./store";
import { warn } from "../utils/logger";
import { nextTick } from "vue";
import {
  DESKTOP_SESSION_STUDENT_ACCESS_MENU,
  DESKTOP_SESSION_LEARNER_LED_SELECT_TOPIC,
  DESKTOP_SESSION_LEARNER_LED_SELECT_SKILL,
  DESKTOP_SESSION_LEARNER_LED_CONFIRM_TOPIC,
  DESKTOP_SESSION_LEARNER_LED_SELECT_MODE,
  DESKTOP_SESSION_LEARNER_LED_TUTORIAL_VIDEO,
  DESKTOP_HOME_DEVICE_CHECKS,
  DESKTOP_SESSION_SELF_RATING,
  DESKTOP_SESSION_SUMMARY,
  DESKTOP_CHARACTER_STUDIO,
} from "@/constants/routes";

let router = createRouter({
  history: createMemoryHistory(),
  routes: [
    /**
     * GENERIC
     */
    {
      path: "/error/invalid-app-instance",
      component: () =>
        import(
          /* webpackChunkName: "screens--InvalidApplicationInstance" */ "../screens/InvalidApplicationInstance"
        ),
      name: "InvalidApplicationInstance",
      meta: {
        nullable: false,
        title: "Compass | Session Expired",
      },
    },
    {
      path: "/browser-check",
      component: () =>
        import(
          /* webpackChunkName: "screens--DeviceCheck" */ "../screens/BrowserCheck"
        ),
      name: "BrowserCheck",
      meta: {
        nullable: false,
      },
      abstract: false,
    },
    {
      path: "/redirect",
      component: () =>
        import(
          /* webpackChunkName: "screens--Redirect" */ "../screens/Redirect"
        ),
      name: "Redirect",
      meta: {
        nullable: false,
      },
      props: true,
    },

    /**
     * LEARNOSITY PREVIEW
     */
    {
      path: "/lrn-preview/item",
      component: () =>
        import(
          /* webpackChunkName: "screens--LrnPreview-Item" */ "../screens/lrn-preview/Item"
        ),
      name: "LrnPreview.Item",
    },

    /**
     * AT HOME
     */
    {
      path: "/home",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--Entrance" */ "../screens/desktop/home/Entrance"
        ),
      name: "Desktop.Home.Entrance",
      meta: {
        persist: false,
        nullable: false,
      },
    },

    {
      path: "/home/get-support",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--GetSupport" */ "../screens/desktop/home/GetSupport"
        ),
      name: "Desktop.Home.GetSupport",
      meta: {
        persist: false,
        nullable: false,
      },
    },

    {
      path: "/home/auth",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--Auth" */ "../screens/desktop/home/Auth"
        ),
      name: "Desktop.Home.Auth",
      meta: {
        nullable: true,
      },
    },

    {
      path: "/home/system-check",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--SystemCheck" */ "../screens/desktop/home/SystemCheck"
        ),
      name: "Desktop.Home.SystemCheck",
      meta: {
        persist: true,
      },
    },

    {
      path: "/home/minimal-system-check",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--MinimalSystemCheck" */ "../screens/desktop/home/MinimalSystemCheck"
        ),
      name: "Desktop.Home.MinimalSystemCheck",
      meta: {
        persist: true,
      },
    },

    {
      path: "/home/family-home",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--FamilyHome" */ "../screens/desktop/home/FamilyHome"
        ),
      name: "Desktop.Home.FamilyHome",
      meta: {
        persist: true,
      },
    },

    {
      path: "/home/student-home",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--StudentHome" */ "../screens/desktop/home/StudentHome"
        ),
      name: "Desktop.Home.StudentHome",
      meta: {
        persist: true,
      },
    },

    {
      path: "/home/profile",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--ProfileHome" */ "../screens/desktop/home/ProfileHome"
        ),
      name: "Desktop.Home.ProfileHome",
      meta: {
        persist: true,
      },
    },

    {
      path: "/home/avatar-selector",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--AvatarSelector" */ "../screens/desktop/home/AvatarSelector"
        ),
      name: "Desktop.Home.AvatarSelector",
      meta: {
        persist: false,
        nullable: false,
      },
    },

    {
      path: "/home/skin-selector",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--SkinSelector" */ "../screens/desktop/home/SkinSelector"
        ),
      name: "Desktop.Home.SkinSelector",
      meta: {
        persist: false,
        nullable: false,
      },
    },

    {
      path: "/home/waiting-room/independent",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--WaitingRoomIndependent" */ "../screens/desktop/home/WaitingRoomIndependent"
        ),
      name: "Desktop.Home.WaitingRoom.Independent",
      meta: {
        persist: false,
      },
    },

    {
      path: "/home/waiting-room/scheduled",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Home--WaitingRoomScheduled" */ "../screens/desktop/home/WaitingRoomScheduled"
        ),
      name: "Desktop.Home.WaitingRoom.Scheduled",
      meta: {
        persist: false,
      },
    },

    /**
     * IN CENTRE
     */
    {
      path: "/centre",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Centre--Entrance" */ "../screens/desktop/centre/Entrance"
        ),
      name: "Desktop.Centre.Entrance",
      meta: {
        nullable: false,
      },
    },

    {
      path: "/centre/workstation-setup",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Centre--WorkstationSetup" */ "../screens/desktop/centre/WorkstationSetup"
        ),
      name: "Desktop.Centre.WorkstationSetup",
    },

    {
      path: "/centre/student-selector",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Centre--StudentSelector" */ "../screens/desktop/centre/StudentSelector"
        ),
      name: "Desktop.Centre.StudentSelector",
    },

    /**
     * SESSION
     */
    {
      path: "/session/welcome",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--Welcome" */ "../screens/desktop/session/Welcome"
        ),
      name: "Desktop.Session.Welcome",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/learning-habit",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--LearningHabit" */ "../screens/desktop/session/LearningHabit"
        ),
      name: "Desktop.Session.LearningHabit",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/activity-selector",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--ActivitySelector" */ "../screens/desktop/session/ActivitySelector"
        ),
      name: "Desktop.Session.ActivitySelector",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/profile",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--ProfileSession" */ "../screens/desktop/session/ProfileSession"
        ),
      name: "Desktop.Session.ProfileSession",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/activity-starter",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--ActivityStarter" */ "../screens/desktop/session/ActivityStarter"
        ),
      name: "Desktop.Session.ActivityStarter",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/assessment-intro",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--AssessmentIntro" */ "../screens/desktop/session/AssessmentIntro"
        ),
      name: "Desktop.Session.AssessmentIntro",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/assessment-end",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--AssessmentEnd" */ "../screens/desktop/session/AssessmentEnd"
        ),
      name: "Desktop.Session.AssessmentEnd",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/activity",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--Activity" */ "../screens/desktop/session/Activity"
        ),
      name: "Desktop.Session.Activity",
      meta: {
        persist: true,
      },
    },
    {
      path: "/session/activity-lesson",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--ActivityScrollThrough" */ "../screens/desktop/session/ActivityScrollThrough"
        ),
      name: "Desktop.Session.ActivityScrollThrough",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/activity-completed",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--ActivityCompleted" */ "../screens/desktop/session/ActivityCompleted"
        ),
      name: "Desktop.Session.ActivityCompleted",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/session-completed",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--SessionCompleted" */ "../screens/desktop/session/SessionCompleted"
        ),
      name: "Desktop.Session.SessionCompleted",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/self-assessment",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--SelfAssessment" */ "../screens/desktop/session/SelfAssessment"
        ),
      name: DESKTOP_SESSION_SELF_RATING,
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/session-summary",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--SessionSummary" */ "../screens/desktop/session/SessionSummary"
        ),
      name: DESKTOP_SESSION_SUMMARY,
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/external-activity-starter",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--ExternalActivityStarter" */ "../screens/desktop/session/ExternalActivityStarter"
        ),
      name: "Desktop.Session.ExternalActivityStarter",
      meta: {
        persist: true,
      },
    },

    {
      path: "/session/group-mode",
      component: () =>
        import(
          /* webpackChunkName: "screens--Desktop--Session--GroupMode" */ "../screens/desktop/session/GroupMode"
        ),
      name: "Desktop.Session.GroupMode",
      meta: {
        persist: true,
      },
    },
    {
      path: "/session/student-access-menu",
      component: () => import("../screens/desktop/session/StudentAccessMenu"),
      name: DESKTOP_SESSION_STUDENT_ACCESS_MENU,
      meta: {
        persist: true,
      },
    },
    {
      path: "/session/learner-led/select-topic",
      component: () =>
        import("../screens/desktop/session/learner-led/SelectTopic"),
      name: DESKTOP_SESSION_LEARNER_LED_SELECT_TOPIC,
      meta: {
        persist: true,
      },
    },
    {
      path: "/session/learner-led/select-skill",
      component: () =>
        import("../screens/desktop/session/learner-led/GCSE/SelectSkill"),
      name: DESKTOP_SESSION_LEARNER_LED_SELECT_SKILL,
      meta: {
        persist: true,
      },
    },
    {
      path: "/session/learner-led/confirm-topic",
      component: () =>
        import("../screens/desktop/session/learner-led/ConfirmTopic"),
      name: DESKTOP_SESSION_LEARNER_LED_CONFIRM_TOPIC,
      meta: {
        persist: true,
      },
    },
    {
      path: "/session/learner-led/select-mode",
      component: () =>
        import("../screens/desktop/session/learner-led/SelectMode"),
      name: DESKTOP_SESSION_LEARNER_LED_SELECT_MODE,
      meta: {
        persist: true,
      },
    },
    {
      path: "/session/learner-led/tutorial-video",
      component: () =>
        import("../screens/desktop/session/learner-led/TutorialVideo"),
      name: DESKTOP_SESSION_LEARNER_LED_TUTORIAL_VIDEO,
      meta: {
        persist: true,
      },
    },
    {
      path: "/home/device-checks",
      component: () => import("../screens/desktop/home/DeviceChecks"),
      name: DESKTOP_HOME_DEVICE_CHECKS,
      meta: {
        persist: false,
      },
    },
    {
      path: "/character-studio",
      component: () => import("../screens/desktop/CharacterStudio"),
      name: DESKTOP_CHARACTER_STUDIO,
      meta: {
        persist: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  var mode = store.getters.mode;

  if (mode != "desktop-home") {
    next();
  } else {
    // let minimalSystemCheckCompleted = CookieJar.get(
    //   store.getters.compass.cookieKeys.homeIndependentSysChecked
    // );
    // let fullSystemCheckCompleted = CookieJar.get(
    //   store.getters.compass.cookieKeys.homeSysChecked
    // );

    if (to.name === "Desktop.Home.StudentHome") {
      if (from.name === "Desktop.Session.GroupMode") {
        router.push({ name: "Desktop.Session.Welcome" }).catch(() => {});
      } else {
        next();
      }
      // else if (minimalSystemCheckCompleted || fullSystemCheckCompleted) {
      //     try {
      //         next();
      //     }
      //     catch (e) { warn(e); }
      // }
      // else {
      //     if (from.name === "Desktop.Home.FamilyHome" || from.name === "Desktop.Session.SessionCompleted") {
      //         next();
      //     }
      //     else {
      //         router.push({ name: 'Desktop.Home.SystemCheck' });
      //     }
      // }
      //   else if (to.name === "Desktop.Session.Welcome") {
      //    if (minimalSystemCheckCompleted || fullSystemCheckCompleted) {
      //      try {
      //        next();
      //      } catch (e) {
      //        warn(e);
      //      }
      //    } else {
      //      router.push({ name: "Desktop.Home.MinimalSystemCheck" });
      //    }
      //  }
    } else {
      try {
        next();
      } catch (e) {
        warn(e);
      }
    }
  }
});
const DEFAULT_TITLE = "Compass | Explore Learning";

router.afterEach((to) => {
  // to, from
  if (to.meta && to.meta.persist === true) {
    store.commit("setRestoreRoute", to.name);
  } else if (to.meta && to.meta.nullable !== false && to.fullPath !== "/") {
    store.commit("setRestoreRoute", null);
  }

  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
