export const COMMIT_SELECTED_TOPIC = "COMMIT_SELECTED_TOPIC";
export const COMMIT_NOTICE_LOADING_TEXT = "COMMIT_NOTICE_LOADING_TEXT";
export const COMMIT_LESSON = "COMMIT_LESSON";
export const COMMIT_SCROLL_THROUGH_QUESTIONS_META =
  "COMMIT_SCROLL_THROUGH_QUESTIONS_META";
export const COMMIT_ELEVEN_PLUS_ACTIVITIES = "COMMIT_ELEVEN_PLUS_ACTIVITIES";
export const COMMIT_UNRESTRICTED_ACTIVITIES = "COMMIT_UNRESTRICTED_ACTIVITIES";
export const COMMIT_EXAM = "COMMIT_EXAM";
export const COMMIT_FEATURE_FLAG = "COMMIT_FEATURE_FLAG";
export const COMMIT_CURRENT_QUESTION = "COMMIT_CURRENT_QUESTION";
export const COMMIT_TOPIC_AUTOSTART_CONFIG = "COMMIT_TOPIC_AUTOSTART_CONFIG";
export const COMMIT_STUDENT_AUTOSELECT = "COMMIT_STUDENT_AUTOSELECT";
export const COMMIT_STEP_BY_STEP_RESPONSES = "COMMIT_STEP_BY_STEP_RESPONSES";
export const COMMIT_STEP_BY_STEP_MARKING_META =
  "COMMIT_STEP_BY_STEP_MARKING_META";
export const COMMIT_CLEAR_STEP_BY_STEP_QUESTION_DATA =
  "COMMIT_CLEAR_STEP_BY_STEP_QUESTION_DATA";
export const COMMIT_STEP_BY_STEP_COMPLETE = "COMMIT_STEP_BY_STEP_COMPLETE";
export const COMMIT_ACTIVITIES_META = "COMMIT_ACTIVITIES_META";
export const COMMIT_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING =
  "COMMIT_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING";
export const COMMIT_TUTOR_SESSION_TYPE = "COMMIT_TUTOR_SESSION_TYPE";
export const COMMIT_RUM_ENABLED = "COMMIT_RUM_ENABLED";

export const COMMIT_GCSE_QUERY_SESSION = "COMMIT_GCSE_QUERY_SESSION";
export const COMMIT_GCSE_AVAILABLE_SUBJECTS = "COMMIT_GCSE_AVAILABLE_SUBJECTS";
export const COMMIT_GCSE_SELECT_SKILL_SUBJECT =
  "COMMIT_GCSE_SELECT_SKILL_SUBJECT";
export const COMMIT_GCSE_AVAILABLE_AREAS = "COMMIT_GCSE_AVAILABLE_AREAS";
export const COMMIT_GCSE_SELECT_SKILL_AREA = "COMMIT_GCSE_SELECT_SKILL_AREA";
export const COMMIT_GCSE_SELECT_CURRICULUM = "COMMIT_GCSE_SELECT_CURRICULUM";
export const COMMIT_GCSE_AVAILABLE_SKILLS = "COMMIT_GCSE_AVAILABLE_SKILLS";
export const COMMIT_GCSE_SELECT_SKILL = "COMMIT_GCSE_SELECT_SKILL";
export const COMMIT_GCSE_RETURNED_SKILLS = "COMMIT_GCSE_RETURNED_SKILLS";
export const COMMIT_SSO_ROUTE_TO = "COMMIT_SSO_ROUTE_TO";

export const COMMIT_TEMP_FEARLESS_POINTS = "COMMIT_TEMP_FEARLESS_POINTS";
export const COMMIT_FLP = "COMMIT_FLP";

export const COMMIT_STUDENT_CAN_SELF_RATE = "COMMIT_STUDENT_CAN_SELF_RATE";

export const COMMIT_SELECTED_CURRICULUM = "COMMIT_SELECTED_CURRICULUM";