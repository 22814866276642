export const SET_SELECTED_TOPIC = "SET_SELECTED_TOPIC";
export const SET_SESSION = "SET_SESSION";
export const SET_NOTICE_LOADING_TEXT = "SET_NOTICE_LOADING_TEXT";
export const SET_LESSON = "SET_LESSON";
export const SET_SCROLL_THROUGH_QUESTIONS_META =
  "SET_SCROLL_THROUGH_QUESTIONS_META";
export const SET_UNRESTRICTED_ACTIVITIES = "SET_UNRESTRICTED_ACTIVITIES";
export const SET_EXAM = "SET_EXAM";
export const SET_FEATURE_FLAG = "SET_FEATURE_FLAG";
export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";
export const SET_TOPIC_AUTOSTART_CONFIG = "SET_TOPIC_AUTOSTART_CONFIG";
export const SET_STUDENT_AUTOSELECT = "SET_STUDENT_AUTOSELECT";
export const SET_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING =
  "SET_USE_ALTERNATIVE_SIGNALR_CONNECTION_STRING";

export const SET_STEP_BY_STEP_RESPONSES = "SET_STEP_BY_STEP_RESPONSES";
export const SET_STEP_BY_STEP_MARKING_META = "SET_STEP_BY_STEP_MARKING_META";
export const CLEAR_STEP_BY_STEP_QUESTION_DATA =
  "CLEAR_STEP_BY_STEP_QUESTION_DATA";
export const SET_STEP_BY_STEP_COMPLETE = "SET_STEP_BY_STEP_COMPLETE";
export const UPDATE_ACTIVITIES_META = "UPDATE_ACTIVITIES_META";
export const UPDATE_TUTOR_SESSION_TYPE = "UPDATE_TUTOR_SESSION_TYPE";

export const SET_RUM_ENABLED = "SET_RUM_ENABLED";

export const SET_GCSE_QUERY_SESSION = "SET_GCSE_QUERY_SESSION";
export const SET_GCSE_AVAILABLE_SUBJECTS = "SET_GCSE_AVAILABLE_SUBJECTS";
export const SET_GCSE_SELECT_SKILL_SUBJECT = "SET_GCSE_SELECT_SKILL_SUBJECT";
export const SET_GCSE_AVAILABLE_AREAS = "SET_GCSE_AVAILABLE_AREAS";
export const SET_GCSE_SELECT_SKILL_AREA = "SET_GCSE_SELECT_SKILL_AREA";
export const SET_GCSE_SELECT_CURRICULUM = "SET_GCSE_SELECT_CURRICULUM";
export const SET_GCSE_AVAILABLE_SKILLS = "SET_GCSE_AVAILABLE_SKILLS";
export const SET_GCSE_SELECT_SKILL = "SET_GCSE_SELECT_SKILL";
export const SET_GCSE_RETURNED_SKILLS = "SET_GCSE_RETURNED_SKILLS";
export const SET_SSO_ROUTE_TO = "SET_SSO_ROUTE_TO";

export const SET_TEMP_FEARLESS_POINTS = "SET_TEMP_FEARLESS_POINTS";
export const SET_FLP = "SET_FLP";

export const SET_STUDENT_CAN_SELF_RATE = "SET_STUDENT_CAN_SELF_RATE";

export const SET_SELECTED_CURRICULUM = "SET_SELECTED_CURRICULUM";