function tryParseJSON(jsonString) {
  try {
    var o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  } catch {
    /*eslint-disable */
  }
  /*eslint-enable */

  return false;
}

/**
 *
 * @param {*} array - candidates to be added to the array, with { item: [item to be added], shouldAdd: [bool] }
 * @returns array of items with shouldAdd === true
 */
const conditionallyBuildArray = (array) => {
  return array
    .filter((candidate) => {
      return candidate.shouldAdd === true;
    })
    .map((candidate) => candidate.item);
};

/**
 * pipe - function composition
 * @description - first function can have N-arity, subsequent functions must be unary
 * @param  {...any} functions - list of functions to be called sequentially
 * @returns value returned by last function in the list
 */
const pipe =
  (...functions) =>
  (...args) => {
    return functions.reduce((currentValues, currentFunction, currentIndex) => {
      return currentIndex === 0
        ? currentFunction(...currentValues)
        : currentFunction(currentValues);
    }, args);
  };

export { tryParseJSON, conditionallyBuildArray, pipe };
