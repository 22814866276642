// activity events
export const ACTIVITY_TOOLS_INIT = "activity-tools-init";
export const ACTIVITY_TOOLS_RESET = "activity-tools-reset";
export const ACTIVITY_TOOLS_DISABLE = "activity-tools-disable";
export const ACTIVITY_TOOLS_ENABLE = "activity-tools-enable";
export const ACTIVITY_SET_TUTOR_HINT = "activity-set-tutor-hint";
export const ACTIVITY_PLAY_SOUND_EFFECT = "activity-play-sound-effect";
export const ACTIVITY_DESTROY_QUESTION_AUDIO =
  "activity-destroy-question-audio";
export const ACTIVITY_EXAM_OUT_OF_TIME = 'activity-exam-out-of-time';
export const TUTORED_PANEL_ACTIVE = 'tutored-panel-active';
export const TUTORED_PANEL_DOCKED = 'tutorial-panel-docked';

// session events
export const STOP_SIGNALR = "stop-signalr";

// sound effects
export const SOUND_EFFECT_CORRECT = "correct";
export const SOUND_EFFECT_INCORRECT = "incorrect";
export const SOUND_EFFECT_COMPLETE = "complete";
export const SOUND_EFFECT_MASTERED = "Mastered";
export const SOUND_EFFECT_REVISED = "Revised";
export const SOUND_EFFECT_RETAINED = "Retained";
