<template>
  <div ref="wrapper" class="whiteboard__question-wrapper">
    <img v-if="questionImgSrc" :src="questionImgSrc" />
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
export default {
  name: "QuestionSnip",
  data() {
    return {
      questionImgSrc: null,
      iframeData: null,
    };
  },
  mounted() {
    eventBus.$on("question-snip", this.handleSnip);
    eventBus.$on("clear-whiteboard", this.clear);
    eventBus.$on("resize-canvas", this.adjustScale);
  },
  beforeUnmount() {
    eventBus.$off("question-snip", this.handleSnip);
    eventBus.$off("clear-whiteboard", this.clear);
    eventBus.$off("resize-canvas");
    this.adjustScale;
  },
  methods: {
    handleSnip(data) {
      this.clear();
      if (data.imgSrc) {
        this.renderImage(data);
      } else if (data.html) {
        this.renderIframe(data);
      }
    },
    renderImage(data) {
      this.questionImgSrc = data.imgSrc;
    },
    renderIframe(data) {
      this.iframeData = data;
      const iframe = document.createElement("iframe");
      const wrapper = this.$refs.wrapper;
      wrapper.append(iframe);

      iframe.width = data.width;
      iframe.height = data.height;
      iframe.style.overflow = "hidden";
      iframe.id = "question-snip-iframe";
      iframe.style.opacity = "0";

      // if the iframe width is bigger than the whiteboard, scale down
      this.adjustScale();

      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(data.html);
      iframe.contentWindow.document.close();

      let innerDoc = iframe.contentDocument || iframe.contentWindow.document;

      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );

      if (isSafari) {
        if (innerDoc.document) innerDoc = innerDoc.document;
        if (innerDoc.readyState === "complete") {
          // const stageWrapper = innerDoc.querySelector(".activity__lrn");
          const stage = innerDoc.querySelector("#el-lrn-stage")
            ? innerDoc.querySelector("#el-lrn-stage")
            : innerDoc.querySelector(".activity__text-blocks");
          stage.style.scrollBehavior = "auto";
          stage.style.overflow = "hidden";
          stage.scrollTo(0, data.scrollTop);
          iframe.style.opacity = "1";
          // if (stage.scrollHeight > stageWrapper.scrollHeight) {
          //   stageWrapper.style.paddingRight = "12px";
          // }
        }
      } else {
        iframe.addEventListener("load", () => {
          // const stageWrapper = innerDoc.querySelector(".activity__lrn");
          const stage = innerDoc.querySelector("#el-lrn-stage")
            ? innerDoc.querySelector("#el-lrn-stage")
            : innerDoc.querySelector(".activity__text-blocks");
          stage.style.scrollBehavior = "auto";
          stage.style.overflow = "hidden";
          stage.scrollTo(0, data.scrollTop);
          iframe.style.opacity = "1";
          // if (stage.scrollHeight > stageWrapper.scrollHeight) {
          //   stageWrapper.style.paddingRight = "12px";
          // }
        });
      }
    },
    adjustScale() {
      const wrapper = this.$refs.wrapper;
      const iframe = document.getElementById("question-snip-iframe");
      if (this.iframeData && iframe) {
        const stageMaxWidth = wrapper.getBoundingClientRect().width - 30;
        const stageMaxHeight = wrapper.getBoundingClientRect().height - 72;
        const widthScaler = stageMaxWidth / this.iframeData.stageWidth;
        const heightScaler = stageMaxHeight / this.iframeData.stageHeight;
        const finalScaler =
          widthScaler < heightScaler ? widthScaler : heightScaler;

        if (finalScaler < 1) {
          iframe.style.transform = `translate(-50%, -50%) scale(${finalScaler})`;
        } else {
          iframe.style.transform = null;
        }
      }
    },
    clear() {
      this.questionImgSrc = null;
      this.iframeData = null;

      const iframe = document.getElementById("question-snip-iframe");
      if (iframe) {
        iframe.remove();
      }
    },
  },
};
</script>