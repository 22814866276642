<template>
  <div class="external-whiteboard" id="externalWhiteboard">
    <canvas class="external-whiteboard__canvas" ref="externalCanvas"></canvas>

    <div class="whiteboard-loading" v-if="loadingExistingPaths">
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";

export default {
  name: "CommittedBoard",
  data() {
    return {
      canvas: null,
      context: null,
      drawing: false,
      currentPath: {},
      pathHistory: [],
      loadingExistingPaths: true,
      resizeTimeout: null,
    };
  },
  mounted() {
    this.canvas = this.$refs.externalCanvas;
    this.context = this.canvas.getContext("2d");

    // window events
    this.onResize();
    window.addEventListener("mousedown", this.defaultMouseDown, false);

    // eventbus events
    eventBus.$on("new-path", this.onNewPath);
    eventBus.$on("erase", this.onEraseEvent);
    eventBus.$on("stop-erasing", this.onStopErasingEvent);
    eventBus.$on("external-erasing", this.onEraseEvent);
    eventBus.$on("stop-external-erasing", this.onStopErasingEvent);
    eventBus.$on("clear-whiteboard", this.onClear);
    eventBus.$on("existing-paths", this.onExistingPathsEvent);
    eventBus.$on("resize-canvas", this.onResize);
  },
  beforeUnmount() {
    // window events
    window.removeEventListener("mousedown", this.defaultMouseDown, false);

    // eventbus events
    eventBus.$off("new-path", this.onNewPath);
    eventBus.$off("erase", this.onEraseEvent);
    eventBus.$off("stop-erasing", this.onStopErasingEvent);
    eventBus.$off("external-erasing", this.onEraseEvent);
    eventBus.$off("stop-external-erasing", this.onStopErasingEvent);
    eventBus.$off("clear-whiteboard", this.onClear);
    eventBus.$off("existing-paths", this.onExistingPathsEvent);
    eventBus.$off("resize-canvas", this.onResize);
  },
  methods: {
    defaultMouseDown(e) {
      e.preventDefault();
    },
    onClear() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.currentPath = {};
      this.pathHistory = [];
    },
    onResize() {
      this.canvas.width =
        document.getElementById("externalWhiteboard").clientWidth;
      this.canvas.height =
        document.getElementById("externalWhiteboard").clientHeight;
      this.drawPaths(this.pathHistory);
    },
    onExistingPathsEvent(paths) {
      this.loadingExistingPaths = true;
      this.pathHistory = [...this.pathHistory, ...paths];
      this.drawPaths(this.pathHistory, true);
    },
    onNewPath(path) {
      this.pathHistory.push(path);
      this.drawPath(path);
    },
    drawPath(path) {
      const w = this.canvas.width;
      const h = this.canvas.height;

      if (path.points.length > 0) {
        this.context.save();
        this.counter++;
        for (let i = 0; i < path.points.length; i++) {
          const point = path.points[i];

          if (i == 0) {
            this.context.beginPath();
            this.context.globalCompositeOperation = "source-over";
            this.context.strokeStyle = point.color;
            this.context.lineWidth = point.strokeWidth;
            this.context.lineJoin = "round";
            this.context.lineCap = "round";
          }

          this.context.lineTo(point.x * w, point.y * h);
          this.context.stroke();
        }
      }
    },
    drawPaths(paths, awaitAnimationFrame) {
      // clear canvas
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        const w = this.canvas.width;
        const h = this.canvas.height;

        for (let x = 0; x < path.points.length; x++) {
          const point = path.points[x];

          if (!point.erase) {
            if (x == 0) {
              this.context.beginPath();
              this.context.globalCompositeOperation = "source-over";
              this.context.strokeStyle = point.color;
              this.context.lineWidth = point.strokeWidth;
              this.context.lineJoin = "round";
              this.context.lineCap = "round";
            }

            this.context.lineTo(point.x * w, point.y * h);
            this.context.stroke();
          } else {
            this.context.beginPath();
            this.context.globalCompositeOperation = "destination-out";
            this.context.arc(
              point.x * w,
              point.y * h,
              point.radius,
              0,
              Math.PI * 2,
              false
            );
            this.context.fill();
          }
        }
      }

      if (awaitAnimationFrame) {
        this.getAnimationFrame().then(() => {
          this.loadingExistingPaths = false;
        });
      }
    },
    getAnimationFrame() {
      return new Promise(function (resolve) {
        requestAnimationFrame(resolve); // this promise never gets rejected
        // TODO: cancellation support :-)
      });
    },
    onEraseEvent(data) {
      const w = this.canvas.width;
      const h = this.canvas.height;

      if (data.x && data.y) {
        if (!this.erasing) {
          this.erasing = true;
          this.currentPath = { id: data.pathId, points: [] };
        }

        this.context.beginPath();
        this.context.globalCompositeOperation = "destination-out";
        this.context.arc(
          data.x * w,
          data.y * h,
          data.radius,
          0,
          Math.PI * 2,
          false
        );
        this.context.fill();

        this.currentPath.points.push(data);
      }
    },
    onStopErasingEvent() {
      if (!this.erasing) {
        return;
      }

      this.erasing = false;
      this.pathHistory.push(this.currentPath);
      this.currentPath = {};
    },
  },
};
</script>