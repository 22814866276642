// DESKTOP
// Learner led
export const DESKTOP_SESSION_STUDENT_ACCESS_MENU =
  "Desktop.Session.StudentAccessMenu";
export const DESKTOP_SESSION_LEARNER_LED_SELECT_TOPIC =
  "Desktop.Session.LearnerLed.SelectTopic";
export const DESKTOP_SESSION_LEARNER_LED_SELECT_SKILL =
  "Desktop.Session.LearnerLed.GCSE.SelectSkill";
export const DESKTOP_SESSION_LEARNER_LED_CONFIRM_TOPIC =
  "Desktop.Session.LearnerLed.ConfirmTopic";
export const DESKTOP_SESSION_LEARNER_LED_SELECT_MODE =
  "Desktop.Session.LearnerLed.SelectMode";
export const DESKTOP_SESSION_ACTIVITY_SCROLL_THROUGH =
  "Desktop.Session.ActivityScrollThrough";
export const DESKTOP_SESSION_LEARNER_LED_TUTORIAL_VIDEO =
  "Desktop.Session.LearnerLed.TutorialVideo";
export const DESKTOP_HOME_DEVICE_CHECKS = "Desktop.Home.DeviceChecks";
export const DESKTOP_SESSION_SELF_RATING = "Desktop.Session.SelfAssessment";
export const DESKTOP_SESSION_SUMMARY = "Desktop.Session.SessionSummary";

//Character Studio
export const DESKTOP_CHARACTER_STUDIO = "Desktop.CharacterStudio";
