<template>
  <div class="app-update">
    <div class="app-update__box">
      <div class="app-update__top-box">
        <div class="app-update__heading">New update available!</div>

        <div class="app-update__content">
          <p>
            There is a new version of the application available, please press
            the button below to reload.
          </p>
        </div>
      </div>

      <div class="app-update__bottom-box">
        <button class="butt butt--small butt--blue-light" @click="reload">
          Reload
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/components/app-update/app-update";
</style>

<script>
export default {
  name: "AppUpdate",
  methods: {
    reload(e) {
      e.preventDefault();
      return window.location.reload(true);
    },
  },
};
</script>