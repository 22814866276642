import * as rrweb from 'rrweb';
import { error } from '../utils/logger';

const remoteViewer = {
    eventsTimer: null,
    eventsFrequency: 2, // seconds
    events: [],
    eventsStopFunc: null,
};

var settings = null;

export default function (config) {
    settings = config;

    if (!remoteViewer.eventsStopFunc) {
        _reset();

        remoteViewer.eventsStopFunc = _startRecording();

        remoteViewer.eventsTimer = _startSending();
    }
}

function stopRecording() {
    _reset();
}


function _startRecording() {
    return rrweb.record({
        inlineStylesheet: false,
        mousemoveWait: 250,
        emit: (event) => {
            remoteViewer.events.push(event);
        },
    });
}

function _startSending() {
    return setInterval(() => {
        _send();
    }, (remoteViewer.eventsFrequency * 1000));
}

function _send() {

    if (!remoteViewer.events.length) {
        return;
    }

    var eventsToSend = remoteViewer.events.splice(0, remoteViewer.events.length);

    let eventsData = JSON.stringify(eventsToSend);

    if (eventsData.indexOf('rr-ignore') !== -1) {
        eventsData = eventsData.replace("rr-ignore", "hidden");
    }

    var data = {
        tutorSessionId: settings.tutorSessionId,
        sessionId: settings.sessionId,
        remoteEventsId: settings.remoteEventsId,
        events: eventsData,
    };

    var headers = {
        'x-remoteevents-key': settings.key,
    };

    window.axios.post(settings.url + "api/remoteevents/beta", data, headers).then(() => {

    }).catch((err) => {

        error(err);
    });
}

function _reset() {
    if (remoteViewer.eventsTimer !== null) {
        clearInterval(remoteViewer.eventsTimer);
    }

    remoteViewer.eventsTimer = null;

    if (remoteViewer.eventsStopFunc !== null) {
        remoteViewer.eventsStopFunc();
        remoteViewer.eventsStopFunc = null;
    }

    if(remoteViewer.events.length)
    {
        _send();
    }
}

export {
    stopRecording,
}