<template>
  <div v-bind:class="toggleOption" class="lobby__transparent bg-smoke-700">
    <div class="lobby__body">
      <div class="get-support__card">
        <div class="get-support__top">
          <div class="get-support__top-inner">
            <div class="get-support__icon">
              <span>!</span>
            </div>
            <!-- <img :src="imgSrc('compass-error-hardware@2x.png')" class="get-support__graphic" v-show="errorType === 'hardware-no-permission' || errorType === 'hardware-unavailable'">
                        <img :src="imgSrc('compass-error-connection@2x.png')" class="get-support__graphic" v-show="errorType === 'network'">                      
                        <img :src="imgSrc('get-support-graphic@2x.png')" class="get-support__graphic" v-show="errorType=== 'default'"> -->

            <h1 class="get-support__title">{{ displayTitle }}</h1>
            <div class="get-support__instructions">
              <p>{{ displayMessage }}</p>
            </div>
          </div>
        </div>
        <div class="get-support__bottom">
          <div class="get-support__contacts">
            <!--<p><button class="butt butt--full butt--sm butt--blue-light" v-on:click.prevent="reload">Click To Go Back</button></p>-->
            <p>
              <button
                class="butt butt--sm butt--blue-light"
                v-on:click.prevent="reloadSession"
              >
                {{ buttonText }}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../assets/scss/screens/get-support/get-support";
</style>

<script>
import eventBus from '@/utils/eventBus';
import { showErrorNotification } from './ErrorNotification.utils'
export default {
  data() {
    return {
      show: false,
      errorMsg: "",
      displayMessage: "",
      displayTitle: "",
      errorType: "",
      buttonText: "Reload Session",
    };
  },
  mounted() {
    eventBus.$on("show-error-notification", showErrorNotification.bind(this));
  },
  beforeUnmount() {
    eventBus.$off("show-error-notification", showErrorNotification.bind(this));
  },
  computed: {
    phoneNumber: function () {
      return this.$store.getters.compass.supportContacts.phone;
    },
    phoneHours: function () {
      return this.$store.getters.compass.supportContacts.phoneHours;
    },
    email: function () {
      return this.$store.getters.compass.supportContacts.email;
    },
    supportUrl: function () {
      return this.$store.getters.compass.supportContacts.url;
    },

    toggleOption: function () {
      return this.show ? "block" : "hidden";
    },
  },
  watch: {
    errorType() {
      switch (this.errorType) {
        case "hardware-no-permission":
          this.displayTitle = "We cannot access your camera or microphone";
          this.displayMessage =
            "Please give your browser access to your camera and microphone.";

          if (sessionStorage.getItem("retry-audio-only")) {
            this.buttonText = "Reload and attempt an audio only session";
          }

          break;
        case "hardware-unavailable":
          this.displayTitle = "Your camera or microphone is unavailable";
          this.displayMessage =
            "Please check that your webcam/microphone is not in use by another application or browser tab. If the reload does not reconnect your camera or microphone then we recommend that you try restarting your device.";

          if (sessionStorage.getItem("retry-audio-only")) {
            this.buttonText = "Reload and attempt an audio only session";
          }

          break;
        case "network":
          this.displayTitle = "Network error";
          this.displayMessage =
            "There is a problem communicating with Explore Learning, please check that you're connected to the internet.";
          break;
        default:
          this.displayTitle = "Something went wrong";
          this.displayMessage =
            "There seems to be a problem connecting to Explore Learning.";
          break;
      }
    },
  },
  methods: {
    reloadSession() {
      window.location.reload(true);
    },
  },
};
</script>