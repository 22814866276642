<template>
  <div class="w-controls">
    <div class="w-controls__block" :class="{ 'w-controls__block--active': mode === 'pen' }">
      <button class="w-controls__mode" :class="{ 'w-controls__mode--active': mode === 'pen' }"
        @click="(e) => changeMode(e, 'pen')">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="pen" role="img"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M493.26 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.25 18.74l-74.49 74.49L256 127.98 12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.79-.05 2.69-.15l114.14-12.61L384.02 256l34.74-34.74 74.49-74.49c25-25 25-65.52.01-90.51zM118.75 453.39l-67.58 7.46 7.53-67.69 231.24-231.24 31.02-31.02 60.14 60.14-31.02 31.02-231.33 231.33zm340.56-340.57l-44.28 44.28-60.13-60.14 44.28-44.28c4.08-4.08 8.84-4.69 11.31-4.69s7.24.61 11.31 4.69l37.51 37.51c6.24 6.25 6.24 16.4 0 22.63z"
            class=""></path>
        </svg>
      </button>

      <div class="color-picker" v-if="mode === 'pen'">
        <button class="color-picker__toggle" @click="toggleColorDropdown">
          <div :class="`color-picker__color color-picker__color--${color}`"></div>
        </button>

        <div class="color-picker__dropdown" v-if="colorDropdownActive">
          <button class="color-picker__color color-picker__color--black" @click="(e) => changeColor(e, 'black')"></button>
          <button class="color-picker__color color-picker__color--red" @click="(e) => changeColor(e, 'red')"></button>
          <button class="color-picker__color color-picker__color--green" @click="(e) => changeColor(e, 'green')"></button>
        </div>
      </div>

      <div class="stroke-picker" v-if="mode === 'pen'">
        <button class="stroke-picker__toggle" @click="toggleStrokeWidthDropdown">
          <div :class="`stroke-picker__stroke stroke-picker__stroke--${strokeWidth}`"></div>
        </button>

        <div class="stroke-picker__dropdown" v-if="strokeWidthDropdownActive">
          <button class="stroke-picker__stroke stroke-picker__stroke--5" @click="(e) => changeStrokeWidth(e, 5)"></button>
          <button class="stroke-picker__stroke stroke-picker__stroke--10"
            @click="(e) => changeStrokeWidth(e, 10)"></button>
          <button class="stroke-picker__stroke stroke-picker__stroke--15"
            @click="(e) => changeStrokeWidth(e, 15)"></button>
        </div>
      </div>
    </div>

    <div class="w-controls__block" :class="{ 'w-controls__block--active': mode === 'eraser' }">
      <button class="w-controls__mode" :class="{ 'w-controls__mode--active': mode === 'eraser' }"
        @click="(e) => changeMode(e, 'eraser')">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="eraser" role="img"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M497.94 273.94a48 48 0 0 0 0-67.88l-160-160a48 48 0 0 0-67.88 0l-256 256a48 48 0 0 0 0 67.88l96 96A48 48 0 0 0 144 480h356a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12H339.88l158.06-158.06zM304 80l160 160-103 103-160-160zM144 432l-96-96 119-119 160 160-55 55z"
            class=""></path>
        </svg>
      </button>

      <div class="radius-picker" v-if="mode === 'eraser'">
        <button class="radius-picker__toggle" @click="toggleEraserRadiusDropdownActive">
          <div :class="`radius-picker__radius radius-picker__radius--${eraserRadius}`"></div>
        </button>

        <div class="radius-picker__dropdown" v-if="eraserRadiusDropdownActive">
          <button class="radius-picker__radius radius-picker__radius--25"
            @click="(e) => changeEraserRadius(e, 25)"></button>
          <button class="radius-picker__radius radius-picker__radius--50"
            @click="(e) => changeEraserRadius(e, 50)"></button>
          <button class="radius-picker__radius radius-picker__radius--100"
            @click="(e) => changeEraserRadius(e, 100)"></button>
        </div>
      </div>
    </div>

    <div class="w-controls__block w-controls__block--no-padding">
      <button class="w-controls__clear" @click="(e) => clearBoard(e)">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt" role="img"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
            class=""></path>
        </svg>
      </button>
    </div>

    <div class="w-controls__block w-controls__block--no-padding" v-if="isGCSE">
      <button class="w-controls__snip" :class="{
        'w-controls__snip--disabled':
          !canSnip || !questionSnipEnabled,
      }" @click="(e) => this.questionSnipRequest(e)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M502.6 198.6l-61.25-61.25C435.4 131.4 427.3 128 418.8 128H256C220.7 128 191.1 156.7 192 192l.0065 255.1C192 483.3 220.7 512 256 512h192c35.2 0 64-28.8 64-64l.0098-226.7C512 212.8 508.6 204.6 502.6 198.6zM464 448c0 8.836-7.164 16-16 16h-192c-8.838 0-16-7.164-16-16L240 192.1c0-8.836 7.164-16 16-16h128L384 224c0 17.67 14.33 32 32 32h48.01V448zM317.7 96C310.6 68.45 285.8 48 256 48H215.2C211.3 20.93 188.1 0 160 0C131.9 0 108.7 20.93 104.8 48H64c-35.35 0-64 28.65-64 64V384c0 35.34 28.65 64 64 64h96v-48H64c-8.836 0-16-7.164-16-16V112C48 103.2 55.18 96 64 96h16v16c0 17.67 14.33 32 32 32h61.35C190 115.4 220.6 96 256 96H317.7zM160 72c-8.822 0-16-7.176-16-16s7.178-16 16-16s16 7.176 16 16S168.8 72 160 72z" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
import whiteboardApi from "@/api/whiteboard";
import { DESKTOP_SESSION_ACTIVITY_SCROLL_THROUGH } from "@/constants/routes";

export default {
  name: "WhiteboardControls",
  props: ["mode", "color", "strokeWidth", "eraserRadius"],
  data() {
    return {
      colorDropdownActive: false,
      strokeWidthDropdownActive: false,
      eraserRadiusDropdownActive: false,
      questionSnipEnabled: false,
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    isGCSE() {
      return this.$store.getters.isGCSE;
    },
    canSnip() {
      // if on activity route and question is present
      if (this.$route.name == "Desktop.Session.Activity" && this.$store.state.currentQuestionId) {
        return true;
      }

      // if on scroll through route
      if (this.$route.name == DESKTOP_SESSION_ACTIVITY_SCROLL_THROUGH) {
        return true;
      }

      return null;
    }
  },
  mounted() {
    // delay snipping to allow the tutor to connect
    setTimeout(() => {
      this.questionSnipEnabled = true;
    }, 2000);

    eventBus.$on('snipping-timeout', this.initiateSnippingTimeout);
  },
  beforeUnmount() {
    eventBus.$off('snipping-timeout', this.initiateSnippingTimeout);
  },
  methods: {
    toggleColorDropdown() {
      this.colorDropdownActive = !this.colorDropdownActive;
      this.strokeWidthDropdownActive = false;
      this.eraserRadiusDropdownActive = false;
    },
    toggleStrokeWidthDropdown() {
      this.strokeWidthDropdownActive = !this.strokeWidthDropdownActive;
      this.colorDropdownActive = false;
      this.eraserRadiusDropdownActive = false;
    },
    toggleEraserRadiusDropdownActive() {
      this.eraserRadiusDropdownActive = !this.eraserRadiusDropdownActive;
      this.colorDropdownActive = false;
      this.strokeWidthDropdownActive = false;
    },
    changeMode(e, mode) {
      e.preventDefault();
      this.$emit("change-mode", mode);
    },
    changeColor(e, color) {
      e.preventDefault();
      this.$emit("change-color", color);
      this.colorDropdownActive = false;
    },
    changeStrokeWidth(e, width) {
      e.preventDefault();
      this.$emit("change-stroke-width", width);
      this.strokeWidthDropdownActive = false;
    },
    changeEraserRadius(e, width) {
      e.preventDefault();
      this.$emit("change-eraser-radius", width);
      this.eraserRadiusDropdownActive = false;
    },
    clearBoard(e) {
      e.preventDefault();
      eventBus.$emit("clear-whiteboard");

      whiteboardApi.clearRoom();
    },
    questionSnipRequest(e) {
      e.preventDefault();

      if (!this.canSnip) {
        return;
      }

      this.initiateSnippingTimeout();

      eventBus.$emit("question-snip-request");
    },
    initiateSnippingTimeout() {
      // this function is to prevent spamming of snipping
      this.questionSnipEnabled = false;

      setTimeout(() => {
        this.questionSnipEnabled = true;
      }, 5000);
    }
  },
};
</script>

