<template>
  <router-view v-slot="{ Component }">
    <Suspense timeout="0">
      <template #default>
        <component ref="routerView" :is="Component" />
      </template>
      <template #fallback>
        <Lobby>
          <template #body>
            <NoticeLoading :message="$store.state.noticeLoadingText" />
          </template>
        </Lobby>
      </template>
    </Suspense>
  </router-view>
  <award-modal v-if="awardModalLedgerEntry" :ledgerEntry="awardModalLedgerEntry"
    @closeAwardModal="awardModalLedgerEntry = null" />
  <tutored-panel ref="tutoredPanel" v-if="tutoredPanelEnabled" />
  <NetworkResult :networkScore="currentNetworkScore" :showMessage="showNetworkResultMessage" />
  <simulated-tutored-panel ref="simulatedTutoredPanel" v-if="simulatedTutoredPanelEnabled" />
  <error-notification />
  <ManagerObservingNotification />
  <Whiteboard />
  <AppUpdate v-if="appUpdateAvailable" />

  <div class="alternative-signalr-blip" v-if="useAlternativeSignalRConnectionString && mode === 'desktop-home'">
  </div>
</template>

<style lang="scss">
@import "assets/scss/core/core";
@import "assets/scss/learnosity/learnosity";
@import "assets/scss/quifix/quifix";

.alternative-signalr-blip {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 10px;
  height: 10px;
  background-color: #144c70;
  pointer-events: none;
  border: 2px solid #fff;
}
</style>

<script>
import { mapState } from "vuex";
import CookieJar from "js-cookie";
import * as MsSignalR from "@microsoft/signalr";
import preloadImg from "./assets/img/preloadImg.json";
import { browserChecker } from "./assets/js/BrowserChecker";
import {
  default as signalAck,
  ackSignalAsync,
  sendWithNoTracking,
} from "./utils/signalAck";
import eventBus from "./utils/eventBus";
import loadStudentAvatarProfileUtil from "./utils/loadStudentAvatarProfileUtil";
import {
  info,
  error,
  warn,
  setIsSlowMode as setLoggingIsSlowMode,
  SignalRLogger
} from "./utils/logger";
import { default as startRecording, stopRecording } from "./utils/webRecorder";
import { tryParseJSON } from "./utils/utlities";
import ErrorNotification from "./components/ErrorNotifcation.vue";
import NoticeLoading from "./components/NoticeLoading.vue";
import Lobby from "./components/Lobby.vue";
import { v4 as uuid } from "uuid";
import Whiteboard from "./components/whiteboard/Whiteboard.vue";
import SecureLS from "secure-ls";
import AppUpdate from "./components/AppUpdate.vue";
import { abandonSession } from "./utils/sessions";
import { errorMessages } from './constants/errors';
import { STOP_SIGNALR, TUTORED_PANEL_ACTIVE } from "./constants/events";
import "vue-select/dist/vue-select.css";
import { CURRICULUM_ELEVEN_PLUS, CURRICULUM_GCSE } from "./constants/curriculumTypes";
import { UPDATE_TUTOR_SESSION_TYPE } from "./constants/actions";
import { DESKTOP_SESSION_SELF_RATING, DESKTOP_SESSION_SUMMARY } from "./constants/routes";
import axios from "axios";

let sls = new SecureLS({ isCompression: false });

export default {
  components: {
    ErrorNotification,
    Whiteboard,
    AppUpdate,
    NoticeLoading,
    Lobby,
  },
  data() {
    return {
      urlParams: null,
      tutoredPanelEnabled: false,
      simulatedTutoredPanelEnabled: false,
      hostsCentre: [
        "compass-dev.explorelearning.cloud",
        "compass-test.explorelearning.cloud",
        "compass-uat.explorelearning.cloud",
        "compass-staging.explorelearning.cloud",
        "compass.explorelearning.cloud",
      ],
      signalR: {
        credentials: null,
        connection: null,
        started: null,
      },
      awardModalLedgerEntry: null,
      awardModalTimeout: null,
      remoteViewer: {
        screenshotTimer: null,
        screenshotFrequency: 15, // seconds
        eventsTimer: null,
        eventsFrequency: 2, // seconds
        events: [],
        eventsStopFunc: null,
      },
      indicatedPointDuration: 5, // seconds
      tutoringInititated: false,
      networkTester: null,
      currentNetworkQuality: null,
      currentNetworkScore: null,
      isNetworkTesting: false,
      showNetworkResultMessage: false,
      showHardRefreshModal: false,
      remoteEventsId: "",
      currentApplicationInstanceId: "",
      isDestroyingSession: false,
      isTutorEndingSession: false,
      isQuestionLoading: false,
      networkSpeedDownloadImage: null,
      networkSpeedIntervalTime: 30000,
      signalrHasStarted: false,
      signalrStarting: false,
      clearLocalStorageOnDestroy: false,
      appUpdateAvailable: false,
    };
  },
  computed: mapState([
    "deviceHasTouch",
    "session",
    "skin",
    "isTablet",
    "isBeingTutored",
    "mode",
    "tabInvalid",
    "useAlternativeSignalRConnectionString",
    "student",
    "isNewFearlessPointsEnabled"
  ]),
  async mounted() {
    await this.injectLearnosityScript();

    this.init();
    // Will force a load of large images into browser cache
    // Image names are pulled from manifest in
    for (const src of preloadImg.src) {
      new Image().src = this.imgSrc(src);
    }

    var browserInfo = browserChecker();
    var userAgent = browserInfo.userAgent;
    var isTablet = browserInfo.type === "Tablet";
    this.$store.commit("setIsTablet", isTablet);
    this.$store.commit("setUserAgent", userAgent);
    this.setTabInstance();
    eventBus.$on("reboot-soft", this.rebootSoft);
    eventBus.$on(TUTORED_PANEL_ACTIVE, this.handleTutorialPanelEvent);
    eventBus.$on(STOP_SIGNALR, this.stopSignalR);
    loadStudentAvatarProfileUtil(this.$store.getters.studentAvatarProfile);
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);

    let useAlternativeSignalRConnectionString = false;
    let updateAlternativeSignalRConnectionString = false;

    if (urlParams.has("skyFix")) {
      if (urlParams.get("skyFix") === "1") {
        if (this.signalR.connection) {
          this.signalR.connection.stop();
          this.signalrHasStarted = false;
          this.signalrStarting = false;
        }

        useAlternativeSignalRConnectionString = true;
        updateAlternativeSignalRConnectionString = true;
      } else if (urlParams.get("skyFix") === "0") {
        useAlternativeSignalRConnectionString = false;
        updateAlternativeSignalRConnectionString = true;
      }
    }

    if (sls.get("compass-state-v2")) {
      const parsedStore = JSON.parse(sls.get("compass-state-v2"));

      let data = {
        ...parsedStore,
      }

      if (updateAlternativeSignalRConnectionString) {
        data.useAlternativeSignalRConnectionString = useAlternativeSignalRConnectionString;
      }

      data.rumEnabled = false;

      this.$store.commit("initialiseStore", data);
    }

    window.addEventListener("beforeunload", this.cleanUp);
  },
  beforeUnmount() {

    this.cleanUp();

    eventBus.$off('reboot-soft', this.rebootSoft);
    eventBus.$off(TUTORED_PANEL_ACTIVE, this.handleTutorialPanelEvent);
  },
  watch: {
    tabInvalid(newValue) {
      if (newValue) {
        this.$router.push({ name: "InvalidApplicationInstance" });
      }
    },
    deviceHasTouch(newValue) {
      let $html = document.getElementById("html");

      if (newValue) {
        if ($html.classList.contains("device--no-touch")) {
          $html.classList.remove("device--no-touch");
        }
        if (!$html.classList.contains("device--has-touch")) {
          $html.classList.add("device--has-touch");
        }
      } else {
        if ($html.classList.contains("device--has-touch")) {
          $html.classList.remove("device--has-touch");
        }
        if (!$html.classList.contains("device--no-touch")) {
          $html.classList.add("device--no-touch");
        }
      }
    },
    skin(newSkin, oldSkin) {
      let $html = document.getElementById("html");

      if (oldSkin && $html.classList.contains("skin--" + oldSkin)) {
        $html.classList.remove("skin--" + oldSkin);
      }

      if (newSkin && !$html.classList.contains("skin--" + newSkin)) {
        $html.classList.add("skin--" + newSkin);
      }
    },
    student(newValue) {
      let html = document.getElementById('html');
      if (newValue) {
        if (newValue.curriculumType === CURRICULUM_GCSE) {
          html.classList.add('curriculum--gcse');
        } else if (newValue.curriculumType === CURRICULUM_ELEVEN_PLUS) {
          html.classList.add('curriculum--eleven-plus');
        } else {
          html.classList.add('curriculum--default');
        }
      } else {
        html.classList.remove('curriculum--default');
        html.classList.remove('curriculum--eleven-plus');
        html.classList.remove('curriculum--gcse');
      }
    },
    session(newValue, oldValue) {
      if (
        newValue &&
        newValue.id &&
        (!oldValue || !oldValue.id || newValue.id !== oldValue.id)
      ) {
        this.signalRInit(this).catch(err => {
          eventBus.$emit("show-error-notification", err.message);
        })
      }

      if (newValue && newValue.id) {
        // this.remoteViewerScreenshotTimerStart();
        // this.remoteViewerEventsStart();
      } else {
        // this.remoteViewerScreenshotTimerStop();
        this.remoteViewerEventsStop();
      }
    },
    tutoredPanelEnabled(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        if (this.tutoringInititated) {
          if (this.$refs.tutoredPanel) {
            this.$refs.tutoredPanel.tutoringStart();
          }
          this.tutoringInititated = false;
        }
      }
    },
    isBeingTutored(newValue, oldValue) {
      if (oldValue === true && newValue == false) {
        stopRecording();
      }
    },

  },
  methods: {
    setTabInstance() {
      const newTabInstanceId = uuid();
      CookieJar.set("tabInstanceId", newTabInstanceId);
      this.$store.dispatch("setTabInstanceId", newTabInstanceId);
    },
    delay(ms) {
      return new Promise(function (resolve) {
        setTimeout(resolve, ms);
      });
    },
    cleanUp() {
      document.querySelector('#app').removeAttribute('style');
      const mode = this.$store.getters.mode;
      if (mode && mode === "desktop-home") {
        if (this.session && this.session.tutor) {
          var header = {
            messageType: "show-as-disconnected",
            senderId: this.session.id,
            recipientId: this.session.tutor.tutorSessionId,
          };
          sendWithNoTracking(header, {});

          this.deleteNetworkQualityStats();

          this.remoteViewerEventsStop();

          window.removeEventListener("beforeunload", this.cleanUp);
        }
      }

      if (this.networkTester) window.clearInterval(this.networkTester);
      this.networkTester = null;

      this.$store.dispatch("sendLastLoggedSessionEvent");
      this.$store.commit("updateNetworkSpeedDetails", null);

      if (this.clearLocalStorageOnDestroy) {
        var buildNumber = localStorage.getItem("build-number");

        localStorage.clear();

        if (buildNumber) {
          localStorage.setItem("build-number", buildNumber);
        }
      }
    },
    forceChildUpdate() {
      this.$root.$children[0].$forceUpdate();
    },
    ensureSignalrStarted() {
      if (this.signalrHasStarted) return Promise.resolve();

      return this.signalRInit(this);
    },
    init() {
      const returnToSysCheck = localStorage.getItem("return-to-sys-check");
      const returnToSysCheckLegacy = localStorage.getItem("return-to-sys-check-legacy");
      if (returnToSysCheckLegacy) {
        this.$router.push({ name: "Desktop.Home.SystemCheck" })
      } else if (returnToSysCheck) {
        localStorage.removeItem("return-to-sys-check");
        this.$router.push(JSON.parse(returnToSysCheck));
      } else {
        this.urlParams = new URLSearchParams(window.location.search);

        if (
          this.urlParams.has("reset") &&
          this.urlParams.get("reset") === "1"
        ) {
          this.rebootHard();
        } else {
          this.initUi();
        }
      }
    },
    modeDetect() {
      if (
        this.urlParams.has("browser-check") &&
        this.urlParams.get("browser-check").length
      ) {
        return "browser-check";
      }

      if (
        this.urlParams.has("lrn-preview") &&
        this.urlParams.get("lrn-preview").length
      ) {
        return "lrn-preview";
      } else if (
        this.urlParams.has("mode") &&
        window.location.hostname == "localhost"
      ) {
        // Allow developers to easily override the mode via ?mode=centre or ?mode=home
        if (this.urlParams.get("mode") == "centre") {
          return "desktop-centre";
        }

        return "desktop-home";
      } else if (
        process.env.VUE_APP_COMPASS_MODE &&
        process.env.VUE_APP_COMPASS_MODE.length
      ) {
        return process.env.VUE_APP_COMPASS_MODE;
      } else if (this.hostsCentre.includes(window.location.hostname)) {
        return "desktop-centre";
      } else {
        return "desktop-home";
      }
    },
    performNetworkCheck() {
      this.showNetworkResultMessage = false;

      if (this.isNetworkTesting) {
        return;
      }

      if (!this.$store.getters.session) {
        return;
      }

      if (!this.$store.getters.session.tutor) {
        if (this.$route && this.$route.name !== "Desktop.Home.WaitingRoom.Scheduled") {
          return;
        }
      }

      this.isNetworkTesting = true;

      if (navigator && navigator.connection) {
        this.setNetworkQualityFromNavigatorConnection().catch(err => {
          // error handling for waiting room
          if (this.$refs.routerView && this.$route && this.$route.name === "Desktop.Home.WaitingRoom.Scheduled") {
            this.$refs.routerView.abandon();
            return Promise.reject(
              new Error(`${err.message}, removing from waiting room and abandoning session`, { cause: err })
            );
          }
        });
      } else {
        const isInSlowMode = this.$store.state.isInSlowMode;

        if (isInSlowMode) {
          this.sendNetworkQualityToStats("Good", true, false);
        } else {
          if (this.networkSpeedDownloadImage) {
            this.networkSpeedDownloadImage = null;
          }

          this.networkSpeedDownloadImage = new Image();

          let startTime, endTime;
          let imageName = "network-test-tiny.jpg";
          let downloadSize = 497297;

          if (this.currentNetworkScore === "Excellent") {
            imageName = "network-test-large.jpg";
            downloadSize = 3958084;
          } else if (this.currentNetworkScore === "Good") {
            imageName = "network-test-medium.jpg";
            downloadSize = 1934933;
          } else if (this.currentNetworkScore === "Average") {
            imageName = "network-test-small.jpg";
            downloadSize = 977372;
          } else {
            imageName = "network-test-tiny.jpg";
            downloadSize = 497297;
          }

          const imageAddr = `https://compassgbmedia.blob.core.windows.net/pub/${imageName}`;

          this.networkSpeedDownloadImage.onload = () => {
            endTime = new Date().getTime();
            var connectionSpeed = calculateResults(
              startTime,
              endTime,
              downloadSize
            );

            this.setNetworkQualityFromDownloadSpeed(connectionSpeed);
            this.networkSpeedDownloadImage = null;
          };

          this.networkSpeedDownloadImage.onerror = () => {
            this.setNetworkQualityFromDownloadSpeed(0);
            this.networkSpeedDownloadImage = null;
          };

          startTime = new Date().getTime();
          const cacheBuster = `?nnn=${startTime}`;
          this.networkSpeedDownloadImage.src = imageAddr + cacheBuster;
        }
      }

      const calculateResults = (startTime, endTime, downloadSize) => {
        const latency = 1;
        const duration = Math.max((endTime - startTime) / 1000 - latency, 0.1);
        const bitsLoaded = downloadSize * 8;
        const speedBps = (bitsLoaded / duration).toFixed(2);
        const speedKbps = (speedBps / 1024).toFixed(2);
        const speedMbps = (speedKbps / 1024).toFixed(2);
        return speedMbps;
      }
    },
    setNetworkQualityFromDownloadSpeed(connectionSpeed) {
      if (connectionSpeed <= 0) {
        this.isNetworkTesting = false;
        throw new Error(errorMessages.NETWORK_ERROR);
      }

      var quality = "Good";

      if (connectionSpeed < 1) {
        quality = "Poor";
        self.showNetworkResultMessage = true;
      } else if (connectionSpeed >= 1 && connectionSpeed < 3) {
        quality = "Average";
        self.showNetworkResultMessage = true;
      } else if (connectionSpeed >= 3 && connectionSpeed < 10) {
        quality = "Good";
      } else {
        quality = "Excellent";
      }

      this.$store.commit("setNetworkSpeed", connectionSpeed);
      this.isNetworkTesting = false;

      if (this.currentNetworkScore != quality) {
        info("Network Quality: " + quality + ", Speed: " + connectionSpeed);
      }

      this.currentNetworkScore = quality;
      this.$store.commit("setNetworkQuality", quality);
      this.sendNetworkQualityToStats(quality, true, false);

      this.$store.dispatch("logSessionEvent", {
        logEvent: "Network test result",
        category: "Network",
        verbosity: "Information",
      });
    },
    setNetworkQualityFromNavigatorConnection() {
      return new Promise((resolve, reject) => {
        var connectionInfo = navigator.connection;
        if (connectionInfo.rtt === 0 && connectionInfo.downlink == 0) {
          this.isNetworkTesting = false;
          warn("No network detected");
          return;
        }

        var quality = "Good";

        if (
          connectionInfo.effectiveType === "2g" ||
          connectionInfo.effectiveType === "slow-2g"
        ) {
          quality = "Poor";
          self.showNetworkResultMessage = true;
        } else if (connectionInfo.effectiveType === "3g") {
          quality = "Average";
          self.showNetworkResultMessage = true;
        } else {
          quality = "Excellent";
        }

        this.$store.commit("setNetworkSpeed", connectionInfo.downlink);
        this.isNetworkTesting = false;

        if (this.currentNetworkScore != quality) {
          info(
            "Network Quality: " +
            quality +
            ", Speed: " +
            connectionInfo.downlink +
            "Effective Type: " +
            connectionInfo.effectiveType
          );
        }

        this.currentNetworkScore = quality;
        this.$store.commit("setNetworkQuality", quality);
        this.sendNetworkQualityToStats(quality, true, true).catch(err => reject(err));

        this.$store.dispatch("logSessionEvent", {
          logEvent: "Network test result",
          category: "Network",
          verbosity: "Information",
        });
      })
    },
    sendNetworkQualityToStats(
      networkQuality,
      isConnectedToSignalr = true,
      isStandardNetworkCheck = true
    ) {
      return new Promise((resolve, reject) => {
        if (!this.session || this.session?.sessionType === 'Independent') {
          return;
        }

        var self = this;

        var remoteSettings = this.elRemoteEventsApiSettings();

        if (remoteSettings) {
          window.axios
            .put(remoteSettings.url + `api/connectionstats/student?currentSession=${this.session.id}`, {
              sessionId: this.session.id,
              networkQuality: networkQuality,
              timeStamp: Math.round(new Date().getTime() / 1000),
              isConnectedToSignalr: isConnectedToSignalr,
              isStandardConnectionCheck: isStandardNetworkCheck,
            })
            .then(() => {
              self.isNetworkTesting = false;
            })
            .catch((err) => {
              reject(
                new Error(
                  `Connection Stats API call failed for student ${this.$store.state.student?.id}${this.$store.state.session?.id ? `, for session: ${this.$store.state.session.id}` : ''}`,
                  { cause: err }
                )
              )
            });
        }
      })
    },
    deleteNetworkQualityStats() {
      if (!this.session) return;
      var remoteSettings = this.elRemoteEventsApiSettings();

      if (!remoteSettings) return;

      window.axios.delete(
        remoteSettings.url + "api/connectionstats/student/" + this.session.id
      );
    },
    modeSet(mode) {
      this.$store.commit("setMode", mode);
      document.getElementById("html").classList.add("mode--" + mode);
    },
    initUi() {
      this.touchDetectionInit();
      let currentMode = this.modeDetect();

      if (currentMode === "browser-check") {
        this.initUiBrowserCheck(currentMode, "BrowserCheck");
      } else {
        if (
          this.$store.getters.mode &&
          this.$store.getters.mode.length &&
          this.$store.getters.mode !== currentMode
        ) {
          this.$store.commit("setMode", currentMode);
          this.rebootHard(true);
        } else if (currentMode === "lrn-preview") {
          this.initUiLearnosityPreview(currentMode);
        } else if (currentMode === "desktop-centre") {
          this.initUiDesktopCentre(currentMode);
        } else if (currentMode === "desktop-home") {
          this.initUiDesktopHome(currentMode);
        } else {
          throw new Error("Unknown mode detected: '" + currentMode + "'.");
        }
      }
    },
    initUiLearnosityPreview(mode) {
      this.storeReset();
      this.modeSet(mode);
      this.touchDetectionInit();
      this.$router.replace({ name: "LrnPreview.Item" });
    },
    initUiDesktop(mode, routeName) {
      this.modeSet(mode);
      this.$router.replace({ name: routeName });
    },
    initUiBrowserCheck(mode, routeName) {
      this.modeSet(mode);

      this.$router.replace({ name: routeName });
    },
    initUiDesktopCentre(mode) {
      this.initUiDesktop(mode, "Desktop.Centre.Entrance");
    },
    initUiDesktopHome(mode) {
      var self = this;
      if (this.networkTester) window.clearInterval(this.networkTester);
      this.networkTester = null;
      this.sendNetworkQualityToStats(
        "Good",
        true,
        this.hasNavigatorConnection()
      );
      this.performNetworkCheck();

      if (navigator && navigator.connection) {
        this.networkSpeedIntervalTime = 5000;
      }

      this.networkTester = window.setInterval(function () {
        self.performNetworkCheck();
      }, this.networkSpeedIntervalTime);

      window.axios
        .get(this.elApiUrl("/remoteviewer/settings"))
        .then((response) => {
          self.$store.commit("setRemoteEventsSettings", response.data);
          self.initUiDesktop(mode, "Desktop.Home.Entrance");
        })
        .catch(() => {
          // TODO: errors
          // should this open the error dialog?
          // Does the category do anything backend
          self.$store.dispatch("logSessionEvent", {
            logEvent: "Failed to fetch remote events settings",
            category: "RemoteEvents",
            verbosity: "Error",
          });
        });
    },
    simulatedTutoredPanelEnable() {
      this.simulatedTutoredPanelEnabled = true;
    },
    simulatedTutoredPanelDisable() {
      this.simulatedTutoredPanelEnabled = false;
    },
    handleTutorialPanelEvent(active) {
      if (active) {
        this.tutoredPanelEnable();
      } else {
        this.tutoredPanelDisable();
      }
    },
    tutoredPanelEnable() {
      if (this.mode === "desktop-centre") return;

      if (
        this.session &&
        (this.session.sessionType === "Independent" ||
          this.session.sessionType === "Centre")
      )
        return;
      this.$store.commit("setTutoredPanelEnabled", true);
      this.tutoredPanelEnabled = true;
    },
    tutoredPanelDisable() {
      this.$store.commit("setTutoredPanelEnabled", false);
      this.tutoredPanelEnabled = false;
    },
    tutoredPanelTutoringRequest() {
      if (this.$refs["tutoredPanel"]) {
        this.$refs["tutoredPanel"].tutoringRequest();
      }
    },
    logoutAtHome() {
      this.rebootHard();
    },
    rebootHard(forceSoftReload) {
      let cookieKeys = Object.values(this.$store.getters.compass.cookieKeys);

      cookieKeys.forEach((cookieKey) => {
        CookieJar.remove(cookieKey);
      });

      var buildNumber = localStorage.getItem("build-number");

      localStorage.clear();

      if (buildNumber) {
        localStorage.setItem("build-number", buildNumber);
      }

      this.clearLocalStorageOnDestroy = true;
      this.$store.dispatch("setPreventSaveToLocalStorage", true);

      if (forceSoftReload === true) {
        this.reloadSoft();
      } else {
        this.reloadHard();
      }
    },
    notifyOfHardRefresh() {
      this.sendKeepAlive = false;
      var message =
        "We have noticed an issue with your session, please click refresh to help us resolve it.";

      if (this.$refs.tutoredPanel) {
        this.$refs.tutoredPanel.cancelKeepAlive();
        this.$refs.tutoredPanel.errorMsg = message;
      } else {
        alert(message);
        this.reloadSoft();
      }
    },
    rebootSoft() {
      var buildNumber = localStorage.getItem("build-number");
      localStorage.clear();

      if (buildNumber) {
        localStorage.setItem("build-number", buildNumber);
      }

      this.clearLocalStorageOnDestroy = true;
      this.$store.dispatch("setPreventSaveToLocalStorage", true);
      this.reloadSoft();
    },
    reloadSoft() {
      window.location.reload(true);
    },
    reloadHard() {
      window.location = "/";
    },
    setIsQuestionLoading(isQuestionLoading) {
      this.isQuestionLoading = isQuestionLoading;
    },
    storeReset() {
      let mode = this.$store.getters.mode;
      this.$store.commit("restoreDefaultData");
      this.$store.commit("setMode", mode);
    },
    touchDetectionInit() {
      // http://www.stucox.com/blog/you-cant-detect-a-touchscreen/
      this.$store.commit("setDeviceHasTouch", false);
      window.addEventListener("touchstart", this.touchDetected, false);
    },
    touchDetected() {
      this.$store.commit("setDeviceHasTouch", true);
      window.removeEventListener("touchstart", this.touchDetected);
    },
    signalRInit(self) {
      return new Promise((resolve, reject) => {
        if (self.isDestroyingSession || self.signalrStarting) return resolve();

        if (
          self.session &&
          (self.session.sessionType == "Centre" ||
            self.session.sessionType == "Independent")
        ) {
          return resolve();
        }

        self.signalrStarting = true;

        window.axios
          .post(self.elApiUrlRoot(`/signalr/negotiate`), {
            userId: this.session.id,
            useAlternativeConnectionString: this.useAlternativeSignalRConnectionString,
          })
          .then((response) => {
            let connectionInfo = response.data;

            const options = {
              accessTokenFactory: async () => {
                try {
                  let response = await window.axios.post(this.elApiUrlRoot("/signalr/negotiate"), {
                    userId: this.session.id,
                    useAlternativeConnectionString: this.useAlternativeSignalRConnectionString,
                  });
                  let responseData = response.data;

                  if (responseData && responseData.accessToken) {
                    return responseData.accessToken;
                  } else {
                    throw "No Access token received.";
                  }
                } catch (err) {
                  error(`Error in accessTokenFactory with userId: ${this.session?.id} and useAlternativeConnectionString: ${this.useAlternativeSignalRConnectionString}`, { cause: error })
                  throw new Error(err);
                }
              },
            };

            const connection = new MsSignalR.HubConnectionBuilder()
              .withUrl(connectionInfo.url, options)
              .configureLogging(new SignalRLogger())
              .withAutomaticReconnect()
              .build();

            const start = new Promise((resolve, reject) => {
              info("Starting signalr connection");

              connection.start().then(() => {
                resolve();
              }).catch((err) => {
                self.signalrHasStarted = false;
                self.signalrStarting = false;
                // TODO: errors
                // Should this open the error dialog?

                error(
                  `Failed to start signalr connection with error: ${err.toString()}`
                );

                // remove the student from the waiting room and abandon
                if (this.$refs.routerView && this.$route && (this.$route.name === "Desktop.Home.WaitingRoom.Scheduled" || this.$route.name === "Desktop.Home.StudentHome")) {
                  abandonSession(this.$store);
                  this.$router.push({ name: 'Desktop.Home.StudentHome' });
                }

                reject(
                  new Error(
                    `Failed to start signalR Connection`,
                    { cause: err }
                  )
                );
              });
            });
            connection.onclose(() => {
              if (self.isDestroyingSession) return;
              self.sendNetworkQualityToStats(
                "Poor",
                false,
                self.hasNavigatorConnection()
              );
              start.then(() => resolve()).catch(err => reject(err));
            });

            start.then(() => resolve()).catch((err) => reject(err));

            var remoteEventSettings = self.elRemoteEventsApiSettings();
            // Start listening for acknowledged requests
            signalAck(connection, {
              endPoint: remoteEventSettings.url + "api/realtime",
            });

            connection.on("award-balance-updated", (message) => {
              if (tryParseJSON(message.payload)) {
                self.signalROnAwardBalanceUpdated(JSON.parse(message.payload));
              }
            });
            connection.on("show-another", () => {
              self.signalROnShowAnother();
            });
            connection.on("show-original", () => {
              self.signalROnShowOriginal();
            });
            connection.on("session-pointer-update", (message) => {
              self.signalROnSessionPointerUpdate(message.payload);
            });
            connection.on("session-started", () => {
              self.signalROnSessionStarted();
            });
            connection.on("skip-question", () => {
              self.signalROnSkipQuestion();
            });

            connection.on("tutoring-started", (message) => {
              self.signalROnTutoringStarted(message);
            });

            connection.on("tutoring-ended", (message) => {
              self.signalROnTutoringEnded(message);
            });

            connection.on("force-tutoring-ended", (message) => {
              self.forceSignalROnTutoringEnded(message);
            });

            connection.on("end-application-instance", (message) => {
              self.endRedundantApplicationInstance(message);
            });

            connection.on("session-ready", () => {
              self.remoteViewerEventsStart();
            });

            connection.on("refresh-remote-events", (message) => {
              self.refreshEventsHandler(message);
            });

            connection.on("notify-hard-refresh", () => {
              self.notifyOfHardRefresh();
            });
            connection.on("notify-group-mode-started", (message) => {
              self.signalROnGroupModeStarted(message);
            });

            connection.on("notify-group-mode-ended", () => {
              self.signalROnGroupModeEnded();
            });

            connection.on("acknowledge-student", () => {
              self.$root.$emit("tutor-has-ack");
            });

            connection.on("keep-alive", (message) => {
              self.keepAlive(message);
            });

            connection.on("session-ended", (message) => {
              if (!self.isTutorEndingSession) self.sessionEnded(message);
            });

            connection.on("student-self-rating", (message) => {
              // if (!self.isTutorEndingSession) self.sessionEnded(message, self);
              self.studentSelfRating(message);
            });

            connection.on("remove-student", (message) => {
              self.removeStudent(message);
            });

            connection.on("notify-manager-observing", (message) => {
              self.NotifyManagerObserving(message);
            });

            connection.on("notify-student-performance-mode", (message) => {
              self.setStudentIsInSlowMode(message);
            });

            connection.on("force-student-stats-update", () => {
              self.sendNetworkQualityToStats("Good");
            });

            connection.on("activity-termination-request", () => {
              eventBus.$emit("activity-termination-request", true);
            });

            connection.on("force-refresh-group-mode", (message) => {
              if (
                self.$route &&
                self.$route.name === "Desktop.Session.GroupMode"
              ) {
                self.$router.push({
                  name: "Redirect",
                  params: { redirectRoute: "Desktop.Session.GroupMode" },
                });
              } else {
                self.signalROnGroupModeStarted(message);
              }
            });

            connection.on("enable-whiteboard", () => {
              eventBus.$emit("enable-whiteboard");
            });

            connection.on("new-path", (message) => {
              eventBus.$emit("new-path", message.payload);
            });

            connection.on("erasing", (message) => {
              eventBus.$emit("external-erasing", message.payload);
            });

            connection.on("stop-erasing", (message) => {
              eventBus.$emit("stop-external-erasing", message.payload);
            });

            connection.on("clear-whiteboard", () => {
              eventBus.$emit("clear-whiteboard");
            });

            connection.on("request-curriculum-type", (message) => {
              const curriculumType = this.$store.state?.student?.curriculumType;

              if (curriculumType) {
                const header = {
                  messageType: 'update-student-curriculum-type',
                  senderId: this.session?.id,
                  recipientId: message.header?.senderId,
                }

                sendWithNoTracking(header, {
                  curriculumType: curriculumType
                });
              }
            })

            connection.on("session-type", (message) => {
              if (message?.payload?.sessionType) {
                this.$store.dispatch(UPDATE_TUTOR_SESSION_TYPE, message.payload.sessionType);
              }
            });

            self.signalR.connection = connection;
            self.signalrHasStarted = true;
            self.signalrStarting = false;
          })
          .catch((err) => {
            self.signalrHasStarted = false;
            self.signalrStarting = false;
            // TODO: errors
            // should this open the error dialog?
            error(
              `Failed to initialise signalr connection with error: ${err.toString()}`
            );
            throw new Error(
              `Failed to start signalR Connection`,
              { cause: err }
            )
          });
      });
    },
    hasNavigatorConnection() {
      return typeof navigator.connection !== "undefined";
    },
    signalROnAwardBalanceUpdated(message) {
      if (!this.$store.getters.student || !this.$store.getters.student.id) {
        return;
      }

      if (
        this.modeIsDesktopHome() &&
        message.ledgerEntries &&
        message.ledgerEntries.length &&
        message.ledgerEntries[0] &&
        message.ledgerEntries[0].awardCurrencyId
      ) {
        if (message.ledgerEntries[0].adjustment > 0) {
          if (this.awardModalTimeout) {
            clearTimeout(this.awardModalTimeout);
            this.awardModalTimeout = null;
          }

          this.awardModalLedgerEntry = message.ledgerEntries[0];

          if (this.awardModalLedgerEntry.awardCurrencyId) {
            const currentAwards = { ...this.$store.state.awardsGiven };
            currentAwards[this.awardModalLedgerEntry.awardCurrencyId] = true;
            this.$store.commit("setAwardsGiven", currentAwards);
          }

          this.awardModalTimeout = setTimeout(() => {
            this.awardModalLedgerEntry = null;
            this.awardModalTimeout = null;
          }, 10 * 1000);
        } else if (
          message.ledgerEntries[0].adjustment < 0 &&
          this.awardModalLedgerEntry &&
          this.awardModalLedgerEntry.awardCurrencyId ===
          message.ledgerEntries[0].awardCurrencyId
        ) {
          if (this.awardModalTimeout) {
            clearTimeout(this.awardModalTimeout);
            this.awardModalTimeout = null;
          }

          this.awardModalLedgerEntry = null;
        }
      }

      if (message.balances && message.balances.length) {
        this.$store.commit("setAwardBalances", message.balances);
      }

      if (
        this.$route &&
        this.$route.name === "Desktop.Session.SessionCompleted" &&
        this.$refs.routerView
      ) {
        this.$refs.routerView.sessionLoad();
      }
    },
    signalROnShowAnother() {
      if (
        this.$refs.routerView &&
        this.$route &&
        this.$route.name === "Desktop.Session.Activity"
      ) {
        this.$refs.routerView.showAnotherStart();
      }
    },
    signalROnShowOriginal() {
      if (
        this.$refs.routerView &&
        this.$route &&
        this.$route.name === "Desktop.Session.Activity"
      ) {
        this.$refs.routerView.showAnotherEnd();
      }
    },
    signalROnSessionPointerUpdate(message) {
      this.tutorClickReceived(
        message.x,
        message.y,
        message.width,
        message.height
      );
    },
    signalROnSessionStarted() {
      if (
        this.$refs.routerView &&
        this.$route &&
        this.$route.name === "Desktop.Home.WaitingRoom.Scheduled"
      ) {
        this.isTutorEndingSession = false;
        this.$refs.routerView.sessionStart();
      }
    },
    signalROnSkipQuestion() {
      if (
        this.$refs.routerView &&
        this.$route &&
        this.$route.name === "Desktop.Session.Activity"
      ) {
        this.$refs.routerView.questionSkipRequest("Manual");
      }
    },
    async sessionEnded(message) {
      if (!this.$store.state.featureFlags.NewAwardSystem) {
        this.isTutorEndingSession = true;
        await ackSignalAsync(message);
        if (this.$store.getters.session) {
          var sessionId = this.$store.getters.session.id;
          if (message.header.recipientId === sessionId) {
            this.$store.commit("setTutorEndedSession");
            if (
              this.$route &&
              this.$route.name === "Desktop.Session.SessionCompleted"
            ) {
              return;
            }
            this.$router.push({ name: "Desktop.Session.SessionCompleted" });
          }
        }
      }
    },

    async studentSelfRating(message) {
      if (this.$store.getters.session) {
        var sessionId = this.$store.getters.session.id;
        if (message.header.recipientId === sessionId) {
          if (this.$store.state.studentCanSelfRate) {
            this.$router.push({ name: DESKTOP_SESSION_SELF_RATING });
          } else {
            await axios.put(this.elApiUrl(`/sessions/${this.$store.state.session.id}`))
              .then(() => {
                info("markSessionAsCompleted function called");
              });
            this.$router.push({ name: "Desktop.Home.StudentHome" });
          }
        }
      }
    },
    async removeStudent(message) {
      this.isTutorEndingSession = true;
      this.tutoredPanelDisable();
      if (this.$store.getters.session) {
        var sessionId = this.$store.getters.session.id;
        if (message.header.recipientId === sessionId) {
          this.$store.commit("setTutorEndedSession");
          if (
            this.$route &&
            (this.$route.name === DESKTOP_SESSION_SUMMARY || this.$route.name === DESKTOP_SESSION_SELF_RATING)
          ) {
            return;
          }
          if (this.$store.state.studentCanSelfRate) {
            this.$router.push({ name: DESKTOP_SESSION_SUMMARY });
          }
          else {
            this.$router.push({ name: "Desktop.Home.StudentHome" });
          }
        }
      }
    },
    async keepAlive(message) {
      await ackSignalAsync(message);
    },
    NotifyManagerObserving(message) {
      eventBus.$emit("notify-manager-observing", message.payload);
    },
    setStudentIsInSlowMode(message) {
      var isInSlowMode = message.payload.performance === "slow";

      setLoggingIsSlowMode(isInSlowMode);

      this.$store.commit("setIsInSlowMode", isInSlowMode);

      var isBeingTutored = this.$store.getters.isBeingTutored;

      if (isBeingTutored) {
        if (isInSlowMode) {
          this.remoteViewerEventsStop();
          if (this.$route && this.$route.name === "Desktop.Session.Activity") {
            var currentQuestionId = this.$store.getters.currentQuestionId;
            if (currentQuestionId) {
              this.sendCurrentQuestionId(currentQuestionId);
            }
          }
        }
      }
    },
    async signalROnTutoringEnded(message) {
      await ackSignalAsync(message);
      this.$store.commit("setIsBeingTutored", false);

      stopRecording();

      if (this.$refs.tutoredPanel) {
        this.$refs.tutoredPanel.tutoringEnded();
      }
    },
    forceSignalROnTutoringEnded(message) {
      var isBeingTutored = this.$store.getters.isBeingTutored;
      if (!isBeingTutored) return;

      if (message)
        info(
          "Forcing tutor ending from tutor session: " + message.header.senderId
        );

      this.$store.commit("setIsBeingTutored", false);

      stopRecording();

      if (this.$refs.tutoredPanel) {
        this.$refs.tutoredPanel.tutoringEnded();
      }
    },
    async signalROnTutoringStarted(message) {
      await ackSignalAsync(message);

      var sessionId = this.$store.getters.session.id;
      this.$store.commit("setIsBeingTutored", true);
      this.tutoringInititated = true;
      var self = this;
      if (self.$refs.tutoredPanel) {
        self.$refs.tutoredPanel.tutoringStart(sessionId);
        self.tutoringInititated = false;
      }
    },
    endRedundantApplicationInstance(message) {
      var currentRunningApplicationInstanceId =
        message.payload.currentApplicationInstanceId;

      if (
        this.currentApplicationInstanceId !==
        currentRunningApplicationInstanceId
      ) {
        this.$router
          .push({ name: "InvalidApplicationInstance" })
          .catch(() => { });
      }
    },
    signalROnGroupModeStarted(message) {
      if (this.$route.name === "Desktop.Session.GroupMode") return;

      this.tutoredPanelDisable();

      this.$store.commit("setIsInGroupMode", true);
      this.$store.commit("setTutorSessionId", message.header.senderId);
      if (this.$route.name !== "Desktop.Session.GroupMode") {
        this.$store.commit("setRestoreRoute", this.$route.name);
      }

      if (!this.isQuestionLoading) {
        this.$router
          .push({ name: "Desktop.Session.GroupMode" })
          .catch(() => { });
      }
    },
    signalROnGroupModeEnded() {
      if (this.$route.name !== "Desktop.Session.GroupMode") {
        return;
      }

      this.tutoredPanelEnable();

      this.$store.commit("setIsInGroupMode", false);

      const restoreRoute = this.$store.state.restoreRoute;

      this.$router.push({ name: restoreRoute }).catch((err) => {
        // TODO: errors
        // should this open the error dialog?
        error(err.toString());

        window.location.reload(false);
      });
    },
    sendCurrentQuestionId(questionId) {
      if (questionId && this.session && this.session.tutor) {
        var header = {
          messageType: "refresh-current-question-id",
          senderId: this.session.id,
          recipientId: this.session.tutor.tutorSessionId,
        };
        sendWithNoTracking(header, {
          currentlyAnsweringQuestionId: questionId,
        }).then(() => { });
      }
    },
    async refreshEventsHandler(message) {
      //await ackSignalAsync(message);
      var isInSlowMode = this.$store.state.isInSlowMode;
      if (isInSlowMode) return;

      this.$store.commit("setRemoteEventsId", message.payload.remoteEventsId);
      this.remoteEventsId = message.payload.remoteEventsId;
      this.refreshEvents();
    },
    async tutoringStartedHandler(message) {
      await ackSignalAsync(message);
      //this.refreshEvents();
    },
    registerNewApplicationId() {
      var session = this.$store.getters.session;

      var promise = window.axios.get(
        this.elApiUrl("/appinstance/" + session.id + "/new")
      );

      var dataPromise = promise.then((response) => response.data);

      return dataPromise;
    },
    setCurrentApplicationInstanceId(applicationInstanceId) {
      this.currentApplicationInstanceId = applicationInstanceId;
    },
    getCurrentApplicationInstanceId() {
      return this.currentApplicationInstanceId;
    },
    refreshEvents(refreshTutorPanel = false) {
      var self = this;

      this.$store.dispatch("logSessionEvent", {
        logEvent: "Refreshing remote events",
        category: "RemoteEvents",
        verbosity: "Information",
      });

      try {
        stopRecording();

        var session = this.$store.getters.session;

        if (session) {
          var sessionId = session.id;

          if (refreshTutorPanel) {
            if (this.$refs.tutoredPanel) {
              this.$refs.tutoredPanel.tutoringEnded();
            }
            this.delay(1000).then(() => {
              self.$refs.tutoredPanel.tutoringStart(sessionId, false);
            });
          }

          self.startSendingEvents();
        } else {
          this.$store.dispatch("logSessionEvent", {
            logEvent: "Trying to refresh remote events on a null session",
            category: "RemoteEvents",
            verbosity: "Warning",
          });
        }
      } catch (err) {
        // TODO: errors
        // should this open the error dialog?
        this.$store.dispatch("logSessionEvent", {
          logEvent: "Error refreshing remote events: " + err.message,
          category: "RemoteEvents",
          verbosity: "Error",
        });
      }
    },
    remoteViewerCapturingAllowed() {
      var isInSlowMode = this.$store.state.isInSlowMode;
      if (isInSlowMode) return false;

      if (!this.remoteEventsId) return false;

      if (
        this.modeIsDesktopHome() &&
        this.session &&
        this.session.id &&
        this.session.tutor &&
        this.session.tutor.tutorSessionId
      ) {
        return true;
      }

      return false;
    },
    startSendingEvents() {
      if (!this.remoteViewerCapturingAllowed()) {
        return;
      }

      this.getRemoteEventSettings().then((remoteEventSettings) => {
        startRecording({
          url: remoteEventSettings.url,
          key: remoteEventSettings.key,
          tutorSessionId: this.session.tutor.tutorSessionId,
          sessionId: this.session.id,
          remoteEventsId: this.remoteEventsId,
        });
      });
    },
    getRemoteEventSettings() {
      var self = this;
      return new Promise(function (resolve) {
        var remoteEventSettings = self.elRemoteEventsApiSettings();

        if (!remoteEventSettings) {
          window.axios
            .get(self.elApiUrl("/remoteviewer/settings"))
            .then((response, resolve) => {
              remoteEventSettings = response.data;
              self.$store.commit("setRemoteEventsSettings", response.data);
              return resolve(remoteEventSettings);
            })
            .catch(() => {
              // TODO: errors
              // should this open the error dialog
              self.$store.dispatch("logSessionEvent", {
                logEvent: "Failed to fetch remote events settings",
                category: "RemoteEvents",
                verbosity: "Error",
              });
            });
        } else {
          return resolve(remoteEventSettings);
        }
      });
    },
    remoteViewerEventsStop() {
      stopRecording();
    },
    tutorClickReceived(
      tutorClickX,
      tutorClickY,
      tutorCanvasWidth,
      tutorCanvasHeight
    ) {
      let indicatedPointSize = 30;

      let studentCanvas = document.getElementById("app");
      let studentCanvasBounding = studentCanvas.getBoundingClientRect();

      let studentCanvasWidth = studentCanvasBounding.width;
      let studentCanvasHeight = studentCanvasBounding.height;

      let scaleX = studentCanvasWidth / tutorCanvasWidth;
      let scaleY = studentCanvasHeight / tutorCanvasHeight;

      let displayLocationX = tutorClickX * scaleX - indicatedPointSize / 2;
      let displayLocationY = tutorClickY * scaleY - indicatedPointSize / 2;

      let indicatedPoint = document.createElement("div");
      indicatedPoint.className = "indicated-point";
      indicatedPoint.style.left = displayLocationX.toString() + "px";
      indicatedPoint.style.top = displayLocationY.toString() + "px";

      studentCanvas.appendChild(indicatedPoint);

      setTimeout(function () {
        indicatedPoint.remove();
      }, this.indicatedPointDuration * 1000);
    },
    destroySession() {
      this.isDestroyingSession = true;

      if (this.signalR && this.signalR.connection) {
        this.signalR.connection.stop();
        // this.signalR.connection = null;
      }

      if (this.networkTester) {
        window.clearInterval(this.networkTester);
        this.networkTester = false;
      }

      stopRecording();

      if (this.$refs.tutoredPanel) {
        this.$refs.tutoredPanel.tutoringEnded();

        this.tutoredPanelDisable();
      }
    },
    async injectLearnosityScript() {
      try {
        const res = await window.axios.get("/api/sri/learnosity");
        if (res.data) {
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.src = res.data.url;
          s.integrity = res.data.hash;
          s.setAttribute("crossorigin", "anonymous");
          document.body.appendChild(s);
        } else {
          throw Error;
        }
      } catch (e) {
        throw new Error(errorMessages.NETWORK_ERROR, { cause: e });
      }
    },
    setAppUpdateAvailable(available) {
      this.appUpdateAvailable = available;
    },
    stopSignalR() {
      if (this.signalR && this.signalR.connection) {
        this.signalR.connection.stop().then(() => {
          info('Hub connection stopped');
        });
        this.signalR.connection = null;
        this.signalrHasStarted = false;
        this.signalrStarting = false;
      }
    }
  },
};
</script>
