export const errorMessages = {
  OT_USER_MEDIA_ACCESS_DENIED: "OT_USER_MEDIA_ACCESS_DENIED",
  OT_HARDWARE_UNAVAILABLE: "OT_HARDWARE_UNAVAILABLE",
  TYPE_ERROR_EVALUATING_THIS_SESSION:
    "TypeError: null is not an object (evaluating 'this.session._')",
  TYPE_ERROR_EVALUATING_N_SESSION:
    "TypeError: null is not an object (evaluating 'n.session._')",
  NAVIGATION_CANCELLED: "Navigation cancelled",
  READING_CLIENT_RECT: "getBoundingClientRect", // TODO: verify our code for unhandled clientRect, typically caused by learnosity and is safe to catch
  RESIZE_OBSERVER_LOOP_LIMIT_EXCEEDED: "ResizeObserver loop limit exceeded",
  GET_USER_MEDIA_NOT_ALLOWED: "getUserMedia error: NotAllowedError",
  CHUNK_LOAD_ERROR: "ChunkLoadError",
  NETWORK_ERROR: "Network Error",
  INDEXEDDB_ERR: "INDEXEDDB_ERR",
  OPENTOK_CHROME_ERROR: ".includes is not a function",
};

export const skipPostSessionErrorMessages = [
  errorMessages.OT_USER_MEDIA_ACCESS_DENIED,
  errorMessages.OT_HARDWARE_UNAVAILABLE,
  errorMessages.TYPE_ERROR_EVALUATING_THIS_SESSION,
  errorMessages.TYPE_ERROR_EVALUATING_N_SESSION,
  errorMessages.NAVIGATION_CANCELLED,
  errorMessages.READING_CLIENT_RECT,
  errorMessages.RESIZE_OBSERVER_LOOP_LIMIT_EXCEEDED,
  errorMessages.OPENTOK_CHROME_ERROR,
];

export const errorMessageTypes = {
  HARDWARE_UNAVAILABLE: "hardware-unavailable",
  HARDWARE_NO_PERMISSION: "hardware-no-permission",
  NETWORK_ERROR: "network",
  DEFAULT: "default",
};
